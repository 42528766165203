import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  Button,
} from "@mui/material";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Restaurant } from "../../API";
import { RestaurantApi } from "../../api-client";
import RestaurantItem from "./RestaurantItem";
import { Refresh, Save } from "@mui/icons-material";

export default function Restaurants() {
  const [loading, setLoading] = useState<string | boolean>(false);
  const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const [expanded, setExpanded] = useState<number | null>(0);

  const handleAccordionChange = (index: number) => {
    setExpanded(expanded === index ? null : index);
  };

  const handleNext = () => {
    if (expanded !== null && expanded < restaurants.length - 1) {
      setExpanded(expanded + 1);
    }

    if (expanded === restaurants.length - 1) {
      setExpanded(0);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await RestaurantApi.getRestaurants();
      // @ts-ignore
      setRestaurants(response.sort((a, b) => a.listRanking - b.listRanking));
    } catch (error) {
      console.error("Error fetching restaurants:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const reordered = Array.from(restaurants);
    const [movedItem] = reordered.splice(result.source.index, 1);
    reordered.splice(result.destination.index, 0, movedItem);

    const updatedRestaurants = reordered.map((restaurant, index) => ({
      ...restaurant,
      listRanking: index + 1,
    }));

    setRestaurants(updatedRestaurants);
    setIsSaveEnabled(true);
  };

  const saveChanges = async () => {
    setLoading(true);
    try {
      await Promise.all(
        restaurants.map((restaurant) =>
          RestaurantApi.updateRestaurantByID({
            id: restaurant.id,
            listRanking: restaurant.listRanking,
          })
        )
      );
      setIsSaveEnabled(false);
      fetchData();
    } catch (error) {
      console.error("Error saving rankings:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        padding: 3,
        borderRadius: "8px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingBottom={1}
      >
        <Typography variant="h6" fontWeight="bold">
          Tasty Restaurants ({restaurants.length})
        </Typography>

        <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            onClick={fetchData}
            endIcon={<Refresh />}
            style={{ marginRight: 4 }}
          >
            <Typography variant="button">Refresh</Typography>
          </Button>

          {isSaveEnabled && (
            <Button
              variant="contained"
              color="warning"
              onClick={saveChanges}
              endIcon={<Save />}
            >
              Save Restaurants
            </Button>
          )}
        </Box>
      </Box>

      {loading && (
        <Backdrop
          open
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="restaurant-list">
          {(provided) => (
            <Box {...provided.droppableProps} ref={provided.innerRef}>
              {restaurants.map((restaurant, index) => (
                <RestaurantItem
                  key={restaurant.id}
                  restaurant={restaurant}
                  index={index}
                  setIsSaveEnabled={setIsSaveEnabled}
                  setLoading={setLoading}
                  reloadRestaurants={fetchData}
                  expanded={expanded === index} // Controlled state
                  onAccordionChange={() => handleAccordionChange(index)}
                  onNext={handleNext} // Next button functionality
                  isLastElement={index === restaurants.length - 1}
                />
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
}
