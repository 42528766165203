import React, { useState } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { Restaurant } from "../../API";

type Props = {
  restaurant: Restaurant;
  setIsSaveEnabled: React.Dispatch<React.SetStateAction<boolean>>;
};

type EditedRestaurant = Record<string, Partial<Restaurant>>; // Map of Restaurant IDs to partial data

export default function BillingDetails({
  restaurant,
  setIsSaveEnabled,
}: Props) {
  const [localRestaurantState, setLocalRestaurantState] =
    useState<EditedRestaurant>(restaurant as unknown as EditedRestaurant);

  const handleInputChange = (key: string, value: any) => {
    // Update local state or send to parent for persistence
    setIsSaveEnabled(true);
    setLocalRestaurantState((prevState) => ({
      ...prevState,
      billingDetails: {
        ...prevState.billingDetails,
        [key]: value,
      },
    }));
  };

  const handleInputChangeAddress = (key: string, value: any) => {
    // Update local state or send to parent for persistence
    setIsSaveEnabled(true);
    setLocalRestaurantState((prevState) => ({
      ...prevState,
      billingDetails: {
        ...prevState.billingDetails,
        address: {
          // @ts-ignore
          ...(prevState.billingDetails?.address || {}),
          [key]: value,
        },
      },
    }));
  };

  return (
    <Box
      width={"60%"}
      sx={{
        padding: 2,
        backgroundColor: "#f9f9f9",
        borderRadius: 1,
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
        margin: "0 10px",
      }}
    >
      {/* General Billing Details */}
      <Typography
        variant="subtitle1"
        sx={{ marginBottom: 1, fontWeight: "bold" }}
      >
        General Billing Details
      </Typography>
      {Object.entries(
        localRestaurantState?.billingDetails || restaurant.billingDetails || {}
      )
        .filter(([key]) => key !== "__typename" && key !== "address")
        .map(([key, value]) => (
          <TextField
            key={key}
            label={key
              .replace(/_/g, " ")
              .replace(/^\w/, (c) => c.toUpperCase())}
            value={value || ""}
            onChange={(e) => handleInputChange(key, e.target.value)}
            fullWidth
            size="small"
            sx={{ marginBottom: 1.5 }}
          />
        ))}

      {/* Address Section */}
      <Typography
        variant="subtitle1"
        sx={{ marginTop: 2, marginBottom: 1, fontWeight: "bold" }}
      >
        Address
      </Typography>
      <Grid container spacing={2}>
        {Object.entries(
          localRestaurantState?.billingDetails.address ||
            restaurant.billingDetails?.address ||
            {}
        ).map(([key, value]) => {
          if (key === "__typename") return null;
          return (
            <Grid item xs={6} key={key}>
              <TextField
                label={key
                  .replace(/_/g, " ")
                  .replace(/^\w/, (c) => c.toUpperCase())}
                value={value || ""}
                onChange={(e) => handleInputChangeAddress(key, e.target.value)}
                fullWidth
                size="small"
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
