import awsconfig from "../aws-exports";

const envStart = awsconfig.aws_user_files_s3_bucket.lastIndexOf("-");
const env = awsconfig.aws_user_files_s3_bucket.slice(envStart + 1);

export function isProd() {
  return env.toUpperCase() === "PROD";
}

export function isStaging() {
  return env.toUpperCase() === "STAGING";
}

export function getBackendEnvironment() {
  return env.toUpperCase();
}

const Colors = {
  primary: "rgba(30, 39, 70, 1)", // #1E2746
  primaryHex: "#1E2746",
  secondary: !isProd() ? "orange" : "rgba(101, 194, 196, 1)", // #65C2C4
  secondaryHex: "#65C2C4",
  brand: "rgba(30, 39, 70, 1)",
  red: "#BC4646",
};

/**
 * Adjusts the opacity of a given color.
 * @param color - A color string in hex format (e.g., "#RRGGBB" or "#RRGGBBAA") or a CSS color string (e.g., "rgb(255, 0, 0)").
 * @param opacity - A number between 0 (fully transparent) and 1 (fully opaque).
 * @returns A string representing the color with the adjusted opacity in rgba format.
 */
export function adjustOpacity(color: string, opacity: number): string {
  if (opacity < 0 || opacity > 1) {
    throw new Error("Opacity must be a number between 0 and 1.");
  }

  // Hex color handling
  if (color.startsWith("#")) {
    const hex = color.replace("#", "");
    let r = 0,
      g = 0,
      b = 0;

    if (hex.length === 3) {
      // Short hex format (#RGB)
      r = parseInt(hex[0] + hex[0], 16);
      g = parseInt(hex[1] + hex[1], 16);
      b = parseInt(hex[2] + hex[2], 16);
    } else if (hex.length === 6 || hex.length === 8) {
      // Full hex format (#RRGGBB or #RRGGBBAA)
      r = parseInt(hex.slice(0, 2), 16);
      g = parseInt(hex.slice(2, 4), 16);
      b = parseInt(hex.slice(4, 6), 16);
    } else {
      throw new Error("Invalid hex color format.");
    }

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  // RGB color handling
  const rgbMatch = color.match(/^rgb(a?)\(([^)]+)\)$/);
  if (rgbMatch) {
    const [, alpha, values] = rgbMatch;
    const [r, g, b] = values.split(",").map((v) => parseInt(v.trim(), 10));

    return `rgba(${r}, ${g}, ${b}, ${alpha ? opacity : 1})`;
  }

  throw new Error(
    "Unsupported color format. Use hex (#RRGGBB or #RGB) or rgb(a)."
  );
}

// Example usage
console.log(adjustOpacity("#3498db", 0.5)); // rgba(52, 152, 219, 0.5)
console.log(adjustOpacity("rgb(255, 0, 0)", 0.8)); // rgba(255, 0, 0, 0.8)

export default Colors;
