import React, { useEffect, useMemo } from "react";

import { Card } from "antd";
import { useTranslation } from "react-i18next";
import { confirmAlert } from "react-confirm-alert"; // Import

import AdminConfigForm from "./admin-config-form";
import {
  Alert,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Switch,
} from "@mui/material";
import { useAppContext } from "../../context/app-context";
import { AppConfigApi } from "../../api-client";

export default function Settings() {
  const { t } = useTranslation();

  const app = useAppContext();
  const isTastyDelivering = useMemo(
    () => !!app.appConfig?.isTastyDelivering,
    [app.appConfig, app.fetchAppConfig]
  );

  const update = async () => {
    await AppConfigApi.updateItem({
      id: app.appConfig?.id!,
      isTastyDelivering: !isTastyDelivering,
    });
    await app.fetchAppConfig();
  };
  const reset = async () => {
    console.log("start isTastyDelivering");
    await app.fetchAppConfig();
    console.log("end isTastyDelivering");
  };

  console.log("isTastyDelivering", isTastyDelivering);

  return (
    <Card title={t("app.side-menu.settings")}>
      <Card
        style={{
          backgroundColor: "#ffa726",
        }}
      >
        <FormControl
          fullWidth
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FormLabel style={{ fontSize: 22 }} color="primary">
            Tasty Lieferservice
          </FormLabel>
          <FormControlLabel
            control={
              <>
                <Switch
                  style={{ color: isTastyDelivering ? "#66bb6a" : "#ffa726" }}
                  color={isTastyDelivering ? "success" : "warning"}
                  defaultChecked={isTastyDelivering}
                  checked={isTastyDelivering}
                  disabled={!app.appConfig?.id}
                  onChange={async () => {
                    if (app.appConfig?.id) {
                      if (isTastyDelivering === true) {
                        // check if user is sure to disable tasty delivery
                        confirmAlert({
                          title: "Tasty deaktivieren", // Title dialog
                          message:
                            "Möchten Sie den Tasty Lieferservice deaktivieren?", // Message dialog
                          childrenElement: () => (
                            <Alert
                              style={{ marginTop: 16 }}
                              severity={"warning"}
                            >
                              {
                                "Warnung! Du bist gerade dabei den Tasty Lieferservice zu deaktivieren."
                              }
                            </Alert>
                          ),

                          buttons: [
                            {
                              label: "Yes",
                              onClick: update,
                            },
                            {
                              label: "No",
                              onClick: reset,
                            },
                          ],
                        });
                      } else {
                        update();
                      }
                    } else {
                      reset();
                    }
                  }}
                />
              </>
            }
            label={""} //isTastyDelivering ? "Aktuell aktiv" : "aktuell deaktiviert"}
            style={{}}
          />
        </FormControl>

        <Alert
          style={{ marginTop: 16 }}
          severity={isTastyDelivering ? "success" : "warning"}
        >
          {isTastyDelivering
            ? " Der Lieferservice ist aktuell aktiviert." +
              " Kunden können Bestellungen aufgeben."
            : " Der Lieferservice ist aktuell deaktiviert." +
              " Kunden können Bestellungen aufgeben falls das Restaurant" +
              " Abholung anbietet oder falls das Restaurant selbst ausliefert."}
        </Alert>
      </Card>

      <AdminConfigForm
        clearOnSuccess={false}
        onCancel={() => console.log("onCancel")}
        onChange={(data) => console.log("onChange", data)}
        onError={(data) => console.log("onError", data)}
        onSubmit={(data) => console.log("onSubmit", data)}
        onSuccess={(data) => console.log("onSuccess", data)}
        onValidate={(data) => console.log("onValidate", data)}
      />
    </Card>
  );
}
