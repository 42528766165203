/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getInvoice = /* GraphQL */ `query GetInvoice($id: ID!) {
  getInvoice(id: $id) {
    id
    amount
    customerFee
    deliveryFee
    restaurantFee
    customerID
    customerEmail
    restaurantID
    paymentIntent
    paymentIntentID
    details {
      name
      description
      price
      options {
        title
        items {
          title
          price
          __typename
        }
        __typename
      }
      __typename
    }
    pdfInvoice
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInvoiceQueryVariables,
  APITypes.GetInvoiceQuery
>;
export const listInvoices = /* GraphQL */ `query ListInvoices(
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      amount
      customerFee
      deliveryFee
      restaurantFee
      customerID
      customerEmail
      restaurantID
      paymentIntent
      paymentIntentID
      details {
        name
        description
        price
        options {
          title
          __typename
        }
        __typename
      }
      pdfInvoice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvoicesQueryVariables,
  APITypes.ListInvoicesQuery
>;
export const syncInvoices = /* GraphQL */ `query SyncInvoices(
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncInvoices(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      amount
      customerFee
      deliveryFee
      restaurantFee
      customerID
      customerEmail
      restaurantID
      paymentIntent
      paymentIntentID
      details {
        name
        description
        price
        options {
          title
          __typename
        }
        __typename
      }
      pdfInvoice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncInvoicesQueryVariables,
  APITypes.SyncInvoicesQuery
>;
export const listInvoicesByCustomerID = /* GraphQL */ `query ListInvoicesByCustomerID(
  $customerID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  listInvoicesByCustomerID(
    customerID: $customerID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      amount
      customerFee
      deliveryFee
      restaurantFee
      customerID
      customerEmail
      restaurantID
      paymentIntent
      paymentIntentID
      details {
        name
        description
        price
        options {
          title
          __typename
        }
        __typename
      }
      pdfInvoice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvoicesByCustomerIDQueryVariables,
  APITypes.ListInvoicesByCustomerIDQuery
>;
export const getDishCategory = /* GraphQL */ `query GetDishCategory($id: ID!) {
  getDishCategory(id: $id) {
    id
    name
    description
    imageKey
    restaurantID
    order
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDishCategoryQueryVariables,
  APITypes.GetDishCategoryQuery
>;
export const listDishCategories = /* GraphQL */ `query ListDishCategories(
  $filter: ModelDishCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listDishCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      imageKey
      restaurantID
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDishCategoriesQueryVariables,
  APITypes.ListDishCategoriesQuery
>;
export const syncDishCategories = /* GraphQL */ `query SyncDishCategories(
  $filter: ModelDishCategoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDishCategories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      description
      imageKey
      restaurantID
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDishCategoriesQueryVariables,
  APITypes.SyncDishCategoriesQuery
>;
export const getDishOptionItem = /* GraphQL */ `query GetDishOptionItem($id: ID!) {
  getDishOptionItem(id: $id) {
    id
    title
    description
    price
    dishOptionID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDishOptionItemQueryVariables,
  APITypes.GetDishOptionItemQuery
>;
export const listDishOptionItems = /* GraphQL */ `query ListDishOptionItems(
  $filter: ModelDishOptionItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listDishOptionItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      description
      price
      dishOptionID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDishOptionItemsQueryVariables,
  APITypes.ListDishOptionItemsQuery
>;
export const syncDishOptionItems = /* GraphQL */ `query SyncDishOptionItems(
  $filter: ModelDishOptionItemFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDishOptionItems(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      title
      description
      price
      dishOptionID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDishOptionItemsQueryVariables,
  APITypes.SyncDishOptionItemsQuery
>;
export const getDishOption = /* GraphQL */ `query GetDishOption($id: ID!) {
  getDishOption(id: $id) {
    id
    title
    description
    multipleSelection
    obligatory
    items {
      items {
        id
        title
        description
        price
        dishOptionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    dishIDs
    restaurantID
    basketDishID
    orderDishID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDishOptionQueryVariables,
  APITypes.GetDishOptionQuery
>;
export const listDishOptions = /* GraphQL */ `query ListDishOptions(
  $filter: ModelDishOptionFilterInput
  $limit: Int
  $nextToken: String
) {
  listDishOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      description
      multipleSelection
      obligatory
      items {
        items {
          id
          title
          description
          price
          dishOptionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      dishIDs
      restaurantID
      basketDishID
      orderDishID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDishOptionsQueryVariables,
  APITypes.ListDishOptionsQuery
>;
export const syncDishOptions = /* GraphQL */ `query SyncDishOptions(
  $filter: ModelDishOptionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDishOptions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      title
      description
      multipleSelection
      obligatory
      items {
        items {
          id
          title
          description
          price
          dishOptionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      dishIDs
      restaurantID
      basketDishID
      orderDishID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDishOptionsQueryVariables,
  APITypes.SyncDishOptionsQuery
>;
export const getDish = /* GraphQL */ `query GetDish($id: ID!) {
  getDish(id: $id) {
    id
    name
    image
    description
    shortDescription
    price
    restaurantID
    soldOut
    category {
      id
      name
      description
      imageKey
      restaurantID
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    allergens {
      id
      name
      __typename
    }
    dishType
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    dishCategoryId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetDishQueryVariables, APITypes.GetDishQuery>;
export const listDishes = /* GraphQL */ `query ListDishes(
  $filter: ModelDishFilterInput
  $limit: Int
  $nextToken: String
) {
  listDishes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      image
      description
      shortDescription
      price
      restaurantID
      soldOut
      category {
        id
        name
        description
        imageKey
        restaurantID
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      allergens {
        id
        name
        __typename
      }
      dishType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dishCategoryId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDishesQueryVariables,
  APITypes.ListDishesQuery
>;
export const syncDishes = /* GraphQL */ `query SyncDishes(
  $filter: ModelDishFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDishes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      image
      description
      shortDescription
      price
      restaurantID
      soldOut
      category {
        id
        name
        description
        imageKey
        restaurantID
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      allergens {
        id
        name
        __typename
      }
      dishType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dishCategoryId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDishesQueryVariables,
  APITypes.SyncDishesQuery
>;
export const getBusinessHourRestaurant = /* GraphQL */ `query GetBusinessHourRestaurant($id: ID!) {
  getBusinessHourRestaurant(id: $id) {
    id
    restaurantID
    periods {
      label
      start
      end
      __typename
    }
    day
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBusinessHourRestaurantQueryVariables,
  APITypes.GetBusinessHourRestaurantQuery
>;
export const listBusinessHourRestaurants = /* GraphQL */ `query ListBusinessHourRestaurants(
  $filter: ModelBusinessHourRestaurantFilterInput
  $limit: Int
  $nextToken: String
) {
  listBusinessHourRestaurants(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      restaurantID
      periods {
        label
        start
        end
        __typename
      }
      day
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBusinessHourRestaurantsQueryVariables,
  APITypes.ListBusinessHourRestaurantsQuery
>;
export const syncBusinessHourRestaurants = /* GraphQL */ `query SyncBusinessHourRestaurants(
  $filter: ModelBusinessHourRestaurantFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBusinessHourRestaurants(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      restaurantID
      periods {
        label
        start
        end
        __typename
      }
      day
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBusinessHourRestaurantsQueryVariables,
  APITypes.SyncBusinessHourRestaurantsQuery
>;
export const listBusinessHourByRestaurantID = /* GraphQL */ `query ListBusinessHourByRestaurantID(
  $restaurantID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelBusinessHourRestaurantFilterInput
  $limit: Int
  $nextToken: String
) {
  listBusinessHourByRestaurantID(
    restaurantID: $restaurantID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      restaurantID
      periods {
        label
        start
        end
        __typename
      }
      day
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBusinessHourByRestaurantIDQueryVariables,
  APITypes.ListBusinessHourByRestaurantIDQuery
>;
export const getBusinessHourTasty = /* GraphQL */ `query GetBusinessHourTasty($id: ID!) {
  getBusinessHourTasty(id: $id) {
    id
    day
    appConfigID
    periods {
      label
      start
      end
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBusinessHourTastyQueryVariables,
  APITypes.GetBusinessHourTastyQuery
>;
export const listBusinessHourTasties = /* GraphQL */ `query ListBusinessHourTasties(
  $filter: ModelBusinessHourTastyFilterInput
  $limit: Int
  $nextToken: String
) {
  listBusinessHourTasties(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      day
      appConfigID
      periods {
        label
        start
        end
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBusinessHourTastiesQueryVariables,
  APITypes.ListBusinessHourTastiesQuery
>;
export const syncBusinessHourTasties = /* GraphQL */ `query SyncBusinessHourTasties(
  $filter: ModelBusinessHourTastyFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBusinessHourTasties(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      day
      appConfigID
      periods {
        label
        start
        end
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBusinessHourTastiesQueryVariables,
  APITypes.SyncBusinessHourTastiesQuery
>;
export const listBusinessHourByAppConfigID = /* GraphQL */ `query ListBusinessHourByAppConfigID(
  $appConfigID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelBusinessHourTastyFilterInput
  $limit: Int
  $nextToken: String
) {
  listBusinessHourByAppConfigID(
    appConfigID: $appConfigID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      day
      appConfigID
      periods {
        label
        start
        end
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBusinessHourByAppConfigIDQueryVariables,
  APITypes.ListBusinessHourByAppConfigIDQuery
>;
export const getAppConfig = /* GraphQL */ `query GetAppConfig($id: ID!) {
  getAppConfig(id: $id) {
    id
    customerFee
    deliveryFee
    restaurantFee
    minimumOrderValue
    openingHours
    businessHours {
      items {
        id
        day
        appConfigID
        periods {
          label
          start
          end
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    maintenance {
      active
      title
      description
      type
      __typename
    }
    taxRate
    customData
    banner {
      visible
      imageURL
      linkURL
      __typename
    }
    orderStop {
      active
      title
      description
      __typename
    }
    voucherEnabled
    isTastyDelivering
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAppConfigQueryVariables,
  APITypes.GetAppConfigQuery
>;
export const listAppConfigs = /* GraphQL */ `query ListAppConfigs(
  $filter: ModelAppConfigFilterInput
  $limit: Int
  $nextToken: String
) {
  listAppConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      customerFee
      deliveryFee
      restaurantFee
      minimumOrderValue
      openingHours
      businessHours {
        items {
          id
          day
          appConfigID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      maintenance {
        active
        title
        description
        type
        __typename
      }
      taxRate
      customData
      banner {
        visible
        imageURL
        linkURL
        __typename
      }
      orderStop {
        active
        title
        description
        __typename
      }
      voucherEnabled
      isTastyDelivering
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAppConfigsQueryVariables,
  APITypes.ListAppConfigsQuery
>;
export const syncAppConfigs = /* GraphQL */ `query SyncAppConfigs(
  $filter: ModelAppConfigFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAppConfigs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      customerFee
      deliveryFee
      restaurantFee
      minimumOrderValue
      openingHours
      businessHours {
        items {
          id
          day
          appConfigID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      maintenance {
        active
        title
        description
        type
        __typename
      }
      taxRate
      customData
      banner {
        visible
        imageURL
        linkURL
        __typename
      }
      orderStop {
        active
        title
        description
        __typename
      }
      voucherEnabled
      isTastyDelivering
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAppConfigsQueryVariables,
  APITypes.SyncAppConfigsQuery
>;
export const getDriver = /* GraphQL */ `query GetDriver($id: ID!) {
  getDriver(id: $id) {
    id
    name
    sub
    email
    password
    lat
    lng
    transportationMode
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetDriverQueryVariables, APITypes.GetDriverQuery>;
export const listDrivers = /* GraphQL */ `query ListDrivers(
  $filter: ModelDriverFilterInput
  $limit: Int
  $nextToken: String
) {
  listDrivers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      sub
      email
      password
      lat
      lng
      transportationMode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDriversQueryVariables,
  APITypes.ListDriversQuery
>;
export const syncDrivers = /* GraphQL */ `query SyncDrivers(
  $filter: ModelDriverFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDrivers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      sub
      email
      password
      lat
      lng
      transportationMode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDriversQueryVariables,
  APITypes.SyncDriversQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    sub
    name
    address
    addressData {
      street
      city
      country
      district
      formattedAddress
      lat
      lng
      __typename
    }
    email
    phone
    lat
    lng
    Orders {
      items {
        id
        userID
        Restaurant {
          id
          adminSub
          listRanking
          name
          minDeliveryTime
          maxDeliveryTime
          rating
          address
          lat
          lng
          imageStorageKey
          status
          phone
          email
          emailPrinter
          autoPickUp
          customData
          printerGCMToken
          loggingEnabled
          deliveryProvider
          defaultDeliveryFee
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        total
        totalPaid
        voucherCode
        voucherAmountOff
        voucherType
        voucherPercentOff
        status
        OrderDishes {
          nextToken
          startedAt
          __typename
        }
        Driver {
          id
          name
          sub
          email
          password
          lat
          lng
          transportationMode
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        read
        printed
        orderNumber
        note
        ready
        createdAt
        orderRestaurantId
        deliveryMethod
        deliveryProvider
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderDriverId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Baskets {
      items {
        id
        BasketDishes {
          nextToken
          startedAt
          __typename
        }
        userID
        restaurantID
        note
        deliveryMethod
        deliveryProvider
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deliveryAddressID
    DeliveryAddresses {
      items {
        id
        name
        addressData {
          street
          city
          country
          district
          formattedAddress
          lat
          lng
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    invoices {
      items {
        id
        amount
        customerFee
        deliveryFee
        restaurantFee
        customerID
        customerEmail
        restaurantID
        paymentIntent
        paymentIntentID
        details {
          name
          description
          price
          __typename
        }
        pdfInvoice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    newsletter
    company
    birthday
    stripeCustomerID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sub
      name
      address
      addressData {
        street
        city
        country
        district
        formattedAddress
        lat
        lng
        __typename
      }
      email
      phone
      lat
      lng
      Orders {
        items {
          id
          userID
          total
          totalPaid
          voucherCode
          voucherAmountOff
          voucherType
          voucherPercentOff
          status
          read
          printed
          orderNumber
          note
          ready
          createdAt
          orderRestaurantId
          deliveryMethod
          deliveryProvider
          updatedAt
          _version
          _deleted
          _lastChangedAt
          orderDriverId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Baskets {
        items {
          id
          userID
          restaurantID
          note
          deliveryMethod
          deliveryProvider
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deliveryAddressID
      DeliveryAddresses {
        items {
          id
          name
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invoices {
        items {
          id
          amount
          customerFee
          deliveryFee
          restaurantFee
          customerID
          customerEmail
          restaurantID
          paymentIntent
          paymentIntentID
          pdfInvoice
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      newsletter
      company
      birthday
      stripeCustomerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const syncUsers = /* GraphQL */ `query SyncUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sub
      name
      address
      addressData {
        street
        city
        country
        district
        formattedAddress
        lat
        lng
        __typename
      }
      email
      phone
      lat
      lng
      Orders {
        items {
          id
          userID
          total
          totalPaid
          voucherCode
          voucherAmountOff
          voucherType
          voucherPercentOff
          status
          read
          printed
          orderNumber
          note
          ready
          createdAt
          orderRestaurantId
          deliveryMethod
          deliveryProvider
          updatedAt
          _version
          _deleted
          _lastChangedAt
          orderDriverId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Baskets {
        items {
          id
          userID
          restaurantID
          note
          deliveryMethod
          deliveryProvider
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deliveryAddressID
      DeliveryAddresses {
        items {
          id
          name
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invoices {
        items {
          id
          amount
          customerFee
          deliveryFee
          restaurantFee
          customerID
          customerEmail
          restaurantID
          paymentIntent
          paymentIntentID
          pdfInvoice
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      newsletter
      company
      birthday
      stripeCustomerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncUsersQueryVariables, APITypes.SyncUsersQuery>;
export const listUsersBySub = /* GraphQL */ `query ListUsersBySub(
  $sub: String!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsersBySub(
    sub: $sub
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sub
      name
      address
      addressData {
        street
        city
        country
        district
        formattedAddress
        lat
        lng
        __typename
      }
      email
      phone
      lat
      lng
      Orders {
        items {
          id
          userID
          total
          totalPaid
          voucherCode
          voucherAmountOff
          voucherType
          voucherPercentOff
          status
          read
          printed
          orderNumber
          note
          ready
          createdAt
          orderRestaurantId
          deliveryMethod
          deliveryProvider
          updatedAt
          _version
          _deleted
          _lastChangedAt
          orderDriverId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Baskets {
        items {
          id
          userID
          restaurantID
          note
          deliveryMethod
          deliveryProvider
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deliveryAddressID
      DeliveryAddresses {
        items {
          id
          name
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invoices {
        items {
          id
          amount
          customerFee
          deliveryFee
          restaurantFee
          customerID
          customerEmail
          restaurantID
          paymentIntent
          paymentIntentID
          pdfInvoice
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      newsletter
      company
      birthday
      stripeCustomerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUsersBySubQueryVariables,
  APITypes.ListUsersBySubQuery
>;
export const getBasketDish = /* GraphQL */ `query GetBasketDish($id: ID!) {
  getBasketDish(id: $id) {
    id
    quantity
    Dish {
      id
      name
      image
      description
      shortDescription
      price
      restaurantID
      soldOut
      category {
        id
        name
        description
        imageKey
        restaurantID
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      allergens {
        id
        name
        __typename
      }
      dishType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dishCategoryId
      __typename
    }
    basketID
    selectedDishOptions {
      items {
        id
        title
        description
        multipleSelection
        obligatory
        items {
          nextToken
          startedAt
          __typename
        }
        dishIDs
        restaurantID
        basketDishID
        orderDishID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    selectedDishOptionsJSON
    note
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    basketDishDishId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBasketDishQueryVariables,
  APITypes.GetBasketDishQuery
>;
export const listBasketDishes = /* GraphQL */ `query ListBasketDishes(
  $filter: ModelBasketDishFilterInput
  $limit: Int
  $nextToken: String
) {
  listBasketDishes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      quantity
      Dish {
        id
        name
        image
        description
        shortDescription
        price
        restaurantID
        soldOut
        category {
          id
          name
          description
          imageKey
          restaurantID
          order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        allergens {
          id
          name
          __typename
        }
        dishType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dishCategoryId
        __typename
      }
      basketID
      selectedDishOptions {
        items {
          id
          title
          description
          multipleSelection
          obligatory
          dishIDs
          restaurantID
          basketDishID
          orderDishID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      selectedDishOptionsJSON
      note
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      basketDishDishId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBasketDishesQueryVariables,
  APITypes.ListBasketDishesQuery
>;
export const syncBasketDishes = /* GraphQL */ `query SyncBasketDishes(
  $filter: ModelBasketDishFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBasketDishes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      quantity
      Dish {
        id
        name
        image
        description
        shortDescription
        price
        restaurantID
        soldOut
        category {
          id
          name
          description
          imageKey
          restaurantID
          order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        allergens {
          id
          name
          __typename
        }
        dishType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dishCategoryId
        __typename
      }
      basketID
      selectedDishOptions {
        items {
          id
          title
          description
          multipleSelection
          obligatory
          dishIDs
          restaurantID
          basketDishID
          orderDishID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      selectedDishOptionsJSON
      note
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      basketDishDishId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBasketDishesQueryVariables,
  APITypes.SyncBasketDishesQuery
>;
export const listBasketDishesByBasketID = /* GraphQL */ `query ListBasketDishesByBasketID(
  $basketID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelBasketDishFilterInput
  $limit: Int
  $nextToken: String
) {
  listBasketDishesByBasketID(
    basketID: $basketID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      quantity
      Dish {
        id
        name
        image
        description
        shortDescription
        price
        restaurantID
        soldOut
        category {
          id
          name
          description
          imageKey
          restaurantID
          order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        allergens {
          id
          name
          __typename
        }
        dishType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dishCategoryId
        __typename
      }
      basketID
      selectedDishOptions {
        items {
          id
          title
          description
          multipleSelection
          obligatory
          dishIDs
          restaurantID
          basketDishID
          orderDishID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      selectedDishOptionsJSON
      note
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      basketDishDishId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBasketDishesByBasketIDQueryVariables,
  APITypes.ListBasketDishesByBasketIDQuery
>;
export const getBasket = /* GraphQL */ `query GetBasket($id: ID!) {
  getBasket(id: $id) {
    id
    BasketDishes {
      items {
        id
        quantity
        Dish {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        basketID
        selectedDishOptions {
          nextToken
          startedAt
          __typename
        }
        selectedDishOptionsJSON
        note
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        basketDishDishId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    userID
    restaurantID
    note
    deliveryMethod
    deliveryProvider
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetBasketQueryVariables, APITypes.GetBasketQuery>;
export const listBaskets = /* GraphQL */ `query ListBaskets(
  $filter: ModelBasketFilterInput
  $limit: Int
  $nextToken: String
) {
  listBaskets(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      BasketDishes {
        items {
          id
          quantity
          basketID
          selectedDishOptionsJSON
          note
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          basketDishDishId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userID
      restaurantID
      note
      deliveryMethod
      deliveryProvider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBasketsQueryVariables,
  APITypes.ListBasketsQuery
>;
export const syncBaskets = /* GraphQL */ `query SyncBaskets(
  $filter: ModelBasketFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBaskets(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      BasketDishes {
        items {
          id
          quantity
          basketID
          selectedDishOptionsJSON
          note
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          basketDishDishId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userID
      restaurantID
      note
      deliveryMethod
      deliveryProvider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBasketsQueryVariables,
  APITypes.SyncBasketsQuery
>;
export const getOrderDish = /* GraphQL */ `query GetOrderDish($id: ID!) {
  getOrderDish(id: $id) {
    id
    quantity
    Dish {
      id
      name
      image
      description
      shortDescription
      price
      restaurantID
      soldOut
      category {
        id
        name
        description
        imageKey
        restaurantID
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      allergens {
        id
        name
        __typename
      }
      dishType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dishCategoryId
      __typename
    }
    orderID
    selectedDishOptions {
      items {
        id
        title
        description
        multipleSelection
        obligatory
        items {
          nextToken
          startedAt
          __typename
        }
        dishIDs
        restaurantID
        basketDishID
        orderDishID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    selectedDishOptionsJSON
    note
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    orderDishDishId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrderDishQueryVariables,
  APITypes.GetOrderDishQuery
>;
export const listOrderDishes = /* GraphQL */ `query ListOrderDishes(
  $filter: ModelOrderDishFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderDishes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      quantity
      Dish {
        id
        name
        image
        description
        shortDescription
        price
        restaurantID
        soldOut
        category {
          id
          name
          description
          imageKey
          restaurantID
          order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        allergens {
          id
          name
          __typename
        }
        dishType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dishCategoryId
        __typename
      }
      orderID
      selectedDishOptions {
        items {
          id
          title
          description
          multipleSelection
          obligatory
          dishIDs
          restaurantID
          basketDishID
          orderDishID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      selectedDishOptionsJSON
      note
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orderDishDishId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderDishesQueryVariables,
  APITypes.ListOrderDishesQuery
>;
export const syncOrderDishes = /* GraphQL */ `query SyncOrderDishes(
  $filter: ModelOrderDishFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOrderDishes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      quantity
      Dish {
        id
        name
        image
        description
        shortDescription
        price
        restaurantID
        soldOut
        category {
          id
          name
          description
          imageKey
          restaurantID
          order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        allergens {
          id
          name
          __typename
        }
        dishType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dishCategoryId
        __typename
      }
      orderID
      selectedDishOptions {
        items {
          id
          title
          description
          multipleSelection
          obligatory
          dishIDs
          restaurantID
          basketDishID
          orderDishID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      selectedDishOptionsJSON
      note
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orderDishDishId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncOrderDishesQueryVariables,
  APITypes.SyncOrderDishesQuery
>;
export const listOrderDishesByOrderID = /* GraphQL */ `query ListOrderDishesByOrderID(
  $orderID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelOrderDishFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderDishesByOrderID(
    orderID: $orderID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      quantity
      Dish {
        id
        name
        image
        description
        shortDescription
        price
        restaurantID
        soldOut
        category {
          id
          name
          description
          imageKey
          restaurantID
          order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        allergens {
          id
          name
          __typename
        }
        dishType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dishCategoryId
        __typename
      }
      orderID
      selectedDishOptions {
        items {
          id
          title
          description
          multipleSelection
          obligatory
          dishIDs
          restaurantID
          basketDishID
          orderDishID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      selectedDishOptionsJSON
      note
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orderDishDishId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderDishesByOrderIDQueryVariables,
  APITypes.ListOrderDishesByOrderIDQuery
>;
export const getOrder = /* GraphQL */ `query GetOrder($id: ID!) {
  getOrder(id: $id) {
    id
    userID
    Restaurant {
      id
      adminSub
      listRanking
      name
      image {
        logoKey
        teaserKey
        __typename
      }
      minDeliveryTime
      maxDeliveryTime
      rating
      address
      lat
      lng
      Dishes {
        items {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Baskets {
        items {
          id
          userID
          restaurantID
          note
          deliveryMethod
          deliveryProvider
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      categories {
        items {
          id
          name
          restaurantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      imageStorageKey
      status
      invoices {
        items {
          id
          amount
          customerFee
          deliveryFee
          restaurantFee
          customerID
          customerEmail
          restaurantID
          paymentIntent
          paymentIntentID
          pdfInvoice
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      phone
      email
      emailPrinter
      autoPickUp
      customData
      billingDetails {
        address {
          city
          country
          line1
          line2
          postal_code
          state
          __typename
        }
        email
        name
        phone
        iban
        __typename
      }
      printerGCMToken
      businessHours {
        items {
          id
          restaurantID
          day
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      loggingEnabled
      deliveryProvider
      defaultDeliveryFee
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    total
    totalPaid
    voucherCode
    voucherAmountOff
    voucherType
    voucherPercentOff
    status
    OrderDishes {
      items {
        id
        quantity
        Dish {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        orderID
        selectedDishOptions {
          nextToken
          startedAt
          __typename
        }
        selectedDishOptionsJSON
        note
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderDishDishId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Driver {
      id
      name
      sub
      email
      password
      lat
      lng
      transportationMode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    read
    printed
    orderNumber
    note
    ready
    createdAt
    orderRestaurantId
    deliveryMethod
    deliveryProvider
    updatedAt
    _version
    _deleted
    _lastChangedAt
    orderDriverId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetOrderQueryVariables, APITypes.GetOrderQuery>;
export const listOrders = /* GraphQL */ `query ListOrders(
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID
      Restaurant {
        id
        adminSub
        listRanking
        name
        image {
          logoKey
          teaserKey
          __typename
        }
        minDeliveryTime
        maxDeliveryTime
        rating
        address
        lat
        lng
        Dishes {
          nextToken
          startedAt
          __typename
        }
        Baskets {
          nextToken
          startedAt
          __typename
        }
        categories {
          nextToken
          startedAt
          __typename
        }
        imageStorageKey
        status
        invoices {
          nextToken
          startedAt
          __typename
        }
        phone
        email
        emailPrinter
        autoPickUp
        customData
        billingDetails {
          email
          name
          phone
          iban
          __typename
        }
        printerGCMToken
        businessHours {
          nextToken
          startedAt
          __typename
        }
        loggingEnabled
        deliveryProvider
        defaultDeliveryFee
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      total
      totalPaid
      voucherCode
      voucherAmountOff
      voucherType
      voucherPercentOff
      status
      OrderDishes {
        items {
          id
          quantity
          orderID
          selectedDishOptionsJSON
          note
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          orderDishDishId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Driver {
        id
        name
        sub
        email
        password
        lat
        lng
        transportationMode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      read
      printed
      orderNumber
      note
      ready
      createdAt
      orderRestaurantId
      deliveryMethod
      deliveryProvider
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orderDriverId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrdersQueryVariables,
  APITypes.ListOrdersQuery
>;
export const syncOrders = /* GraphQL */ `query SyncOrders(
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOrders(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      userID
      Restaurant {
        id
        adminSub
        listRanking
        name
        image {
          logoKey
          teaserKey
          __typename
        }
        minDeliveryTime
        maxDeliveryTime
        rating
        address
        lat
        lng
        Dishes {
          nextToken
          startedAt
          __typename
        }
        Baskets {
          nextToken
          startedAt
          __typename
        }
        categories {
          nextToken
          startedAt
          __typename
        }
        imageStorageKey
        status
        invoices {
          nextToken
          startedAt
          __typename
        }
        phone
        email
        emailPrinter
        autoPickUp
        customData
        billingDetails {
          email
          name
          phone
          iban
          __typename
        }
        printerGCMToken
        businessHours {
          nextToken
          startedAt
          __typename
        }
        loggingEnabled
        deliveryProvider
        defaultDeliveryFee
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      total
      totalPaid
      voucherCode
      voucherAmountOff
      voucherType
      voucherPercentOff
      status
      OrderDishes {
        items {
          id
          quantity
          orderID
          selectedDishOptionsJSON
          note
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          orderDishDishId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Driver {
        id
        name
        sub
        email
        password
        lat
        lng
        transportationMode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      read
      printed
      orderNumber
      note
      ready
      createdAt
      orderRestaurantId
      deliveryMethod
      deliveryProvider
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orderDriverId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncOrdersQueryVariables,
  APITypes.SyncOrdersQuery
>;
export const listOrdersByUserID = /* GraphQL */ `query ListOrdersByUserID(
  $userID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrdersByUserID(
    userID: $userID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userID
      Restaurant {
        id
        adminSub
        listRanking
        name
        image {
          logoKey
          teaserKey
          __typename
        }
        minDeliveryTime
        maxDeliveryTime
        rating
        address
        lat
        lng
        Dishes {
          nextToken
          startedAt
          __typename
        }
        Baskets {
          nextToken
          startedAt
          __typename
        }
        categories {
          nextToken
          startedAt
          __typename
        }
        imageStorageKey
        status
        invoices {
          nextToken
          startedAt
          __typename
        }
        phone
        email
        emailPrinter
        autoPickUp
        customData
        billingDetails {
          email
          name
          phone
          iban
          __typename
        }
        printerGCMToken
        businessHours {
          nextToken
          startedAt
          __typename
        }
        loggingEnabled
        deliveryProvider
        defaultDeliveryFee
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      total
      totalPaid
      voucherCode
      voucherAmountOff
      voucherType
      voucherPercentOff
      status
      OrderDishes {
        items {
          id
          quantity
          orderID
          selectedDishOptionsJSON
          note
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          orderDishDishId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Driver {
        id
        name
        sub
        email
        password
        lat
        lng
        transportationMode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      read
      printed
      orderNumber
      note
      ready
      createdAt
      orderRestaurantId
      deliveryMethod
      deliveryProvider
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orderDriverId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrdersByUserIDQueryVariables,
  APITypes.ListOrdersByUserIDQuery
>;
export const getDeliveryAddress = /* GraphQL */ `query GetDeliveryAddress($id: ID!) {
  getDeliveryAddress(id: $id) {
    id
    name
    addressData {
      street
      city
      country
      district
      formattedAddress
      lat
      lng
      __typename
    }
    userID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeliveryAddressQueryVariables,
  APITypes.GetDeliveryAddressQuery
>;
export const listDeliveryAddresses = /* GraphQL */ `query ListDeliveryAddresses(
  $filter: ModelDeliveryAddressFilterInput
  $limit: Int
  $nextToken: String
) {
  listDeliveryAddresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      addressData {
        street
        city
        country
        district
        formattedAddress
        lat
        lng
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeliveryAddressesQueryVariables,
  APITypes.ListDeliveryAddressesQuery
>;
export const syncDeliveryAddresses = /* GraphQL */ `query SyncDeliveryAddresses(
  $filter: ModelDeliveryAddressFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDeliveryAddresses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      addressData {
        street
        city
        country
        district
        formattedAddress
        lat
        lng
        __typename
      }
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDeliveryAddressesQueryVariables,
  APITypes.SyncDeliveryAddressesQuery
>;
export const getRestaurantCategory = /* GraphQL */ `query GetRestaurantCategory($id: ID!) {
  getRestaurantCategory(id: $id) {
    id
    name
    restaurantID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRestaurantCategoryQueryVariables,
  APITypes.GetRestaurantCategoryQuery
>;
export const listRestaurantCategories = /* GraphQL */ `query ListRestaurantCategories(
  $filter: ModelRestaurantCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listRestaurantCategories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      restaurantID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRestaurantCategoriesQueryVariables,
  APITypes.ListRestaurantCategoriesQuery
>;
export const syncRestaurantCategories = /* GraphQL */ `query SyncRestaurantCategories(
  $filter: ModelRestaurantCategoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncRestaurantCategories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      restaurantID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncRestaurantCategoriesQueryVariables,
  APITypes.SyncRestaurantCategoriesQuery
>;
export const getRestaurant = /* GraphQL */ `query GetRestaurant($id: ID!) {
  getRestaurant(id: $id) {
    id
    adminSub
    listRanking
    name
    image {
      logoKey
      teaserKey
      __typename
    }
    minDeliveryTime
    maxDeliveryTime
    rating
    address
    lat
    lng
    Dishes {
      items {
        id
        name
        image
        description
        shortDescription
        price
        restaurantID
        soldOut
        category {
          id
          name
          description
          imageKey
          restaurantID
          order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        allergens {
          id
          name
          __typename
        }
        dishType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dishCategoryId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Baskets {
      items {
        id
        BasketDishes {
          nextToken
          startedAt
          __typename
        }
        userID
        restaurantID
        note
        deliveryMethod
        deliveryProvider
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    categories {
      items {
        id
        name
        restaurantID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    imageStorageKey
    status
    invoices {
      items {
        id
        amount
        customerFee
        deliveryFee
        restaurantFee
        customerID
        customerEmail
        restaurantID
        paymentIntent
        paymentIntentID
        details {
          name
          description
          price
          __typename
        }
        pdfInvoice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    phone
    email
    emailPrinter
    autoPickUp
    customData
    billingDetails {
      address {
        city
        country
        line1
        line2
        postal_code
        state
        __typename
      }
      email
      name
      phone
      iban
      __typename
    }
    printerGCMToken
    businessHours {
      items {
        id
        restaurantID
        periods {
          label
          start
          end
          __typename
        }
        day
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    loggingEnabled
    deliveryProvider
    defaultDeliveryFee
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRestaurantQueryVariables,
  APITypes.GetRestaurantQuery
>;
export const listRestaurants = /* GraphQL */ `query ListRestaurants(
  $filter: ModelRestaurantFilterInput
  $limit: Int
  $nextToken: String
) {
  listRestaurants(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      adminSub
      listRanking
      name
      image {
        logoKey
        teaserKey
        __typename
      }
      minDeliveryTime
      maxDeliveryTime
      rating
      address
      lat
      lng
      Dishes {
        items {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Baskets {
        items {
          id
          userID
          restaurantID
          note
          deliveryMethod
          deliveryProvider
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      categories {
        items {
          id
          name
          restaurantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      imageStorageKey
      status
      invoices {
        items {
          id
          amount
          customerFee
          deliveryFee
          restaurantFee
          customerID
          customerEmail
          restaurantID
          paymentIntent
          paymentIntentID
          pdfInvoice
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      phone
      email
      emailPrinter
      autoPickUp
      customData
      billingDetails {
        address {
          city
          country
          line1
          line2
          postal_code
          state
          __typename
        }
        email
        name
        phone
        iban
        __typename
      }
      printerGCMToken
      businessHours {
        items {
          id
          restaurantID
          day
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      loggingEnabled
      deliveryProvider
      defaultDeliveryFee
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRestaurantsQueryVariables,
  APITypes.ListRestaurantsQuery
>;
export const syncRestaurants = /* GraphQL */ `query SyncRestaurants(
  $filter: ModelRestaurantFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncRestaurants(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      adminSub
      listRanking
      name
      image {
        logoKey
        teaserKey
        __typename
      }
      minDeliveryTime
      maxDeliveryTime
      rating
      address
      lat
      lng
      Dishes {
        items {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Baskets {
        items {
          id
          userID
          restaurantID
          note
          deliveryMethod
          deliveryProvider
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      categories {
        items {
          id
          name
          restaurantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      imageStorageKey
      status
      invoices {
        items {
          id
          amount
          customerFee
          deliveryFee
          restaurantFee
          customerID
          customerEmail
          restaurantID
          paymentIntent
          paymentIntentID
          pdfInvoice
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      phone
      email
      emailPrinter
      autoPickUp
      customData
      billingDetails {
        address {
          city
          country
          line1
          line2
          postal_code
          state
          __typename
        }
        email
        name
        phone
        iban
        __typename
      }
      printerGCMToken
      businessHours {
        items {
          id
          restaurantID
          day
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      loggingEnabled
      deliveryProvider
      defaultDeliveryFee
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncRestaurantsQueryVariables,
  APITypes.SyncRestaurantsQuery
>;
