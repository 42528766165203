import React from "react";
import { Box, Stack, Typography, Switch } from "@mui/material";
import { Warning as WarningIcon } from "@mui/icons-material";
import { Restaurant, RestaurantStatus } from "../../API";
import ToggleDeliveryProvider from "../../components/toggle-delivery-provider";
import { RestaurantApi } from "../../api-client";

type Props = {
  restaurant: Restaurant;
  setLoading: React.Dispatch<React.SetStateAction<string | boolean>>;
  reloadRestaurants: () => Promise<void>;
};

export default function DangerZone({
  restaurant,
  setLoading,
  reloadRestaurants,
}: Props) {
  const isActive = restaurant.status === RestaurantStatus.ACTIVE;

  const updateDeliveryFee = async (value: number, restaurant: Restaurant) => {
    /*  setRestaurants((prev) =>
      prev.map((r) =>
        r.id === restaurant.id ? { ...r, defaultDeliveryFee: value } : r
      )
    ); */
    await RestaurantApi.updateRestaurantByID({
      id: restaurant.id,
      defaultDeliveryFee: value,
    });

    reloadRestaurants();
  };

  const toggleRestaurantStatus = async () => {
    const id = restaurant.id;
    setLoading(id);
    try {
      const original = await RestaurantApi.getRestaurantByID({ id });
      const updatedStatus =
        original?.status === RestaurantStatus.ACTIVE
          ? RestaurantStatus.INACTIVE
          : RestaurantStatus.ACTIVE;

      await RestaurantApi.updateRestaurantByID({ id, status: updatedStatus });
      reloadRestaurants();
    } catch (error) {
      console.error("Error updating restaurant status:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      width={"40%"}
      sx={{
        backgroundColor: "#ffe5e5",
        padding: 2,
        borderRadius: 1,
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
        margin: "0 10px",
        borderLeft: "8px solid #f44336",
      }}
    >
      <Box>
        <Typography
          variant="subtitle1"
          sx={{ marginBottom: 1, fontWeight: "bold" }}
        >
          Liefereinstellungen
        </Typography>
        <ToggleDeliveryProvider
          restaurant={restaurant}
          updateDeliveryFee={(value) => updateDeliveryFee(value, restaurant)}
          setLoading={setLoading}
        />
      </Box>

      <Box marginTop={2}>
        <Typography
          variant="subtitle1"
          sx={{ marginBottom: 1, fontWeight: "bold" }}
        >
          Restaurant Status
        </Typography>
        <Box flexDirection={"row"} display={"flex"} alignItems={"center"}>
          <Typography
            color={isActive ? "success.main" : "error.main"}
            textTransform={"uppercase"}
            fontWeight={"bold"}
          >
            {isActive ? "Restaurant online" : "Restaurant offline"}
          </Typography>
          <Switch checked={isActive} onChange={toggleRestaurantStatus} />
        </Box>
      </Box>
    </Box>
  );
}
