import React from "react";
import { Paper } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import RestaurantAccordion from "./RestaurantAccordion";
import { Restaurant } from "../../API";

type Props = {
  restaurant: Restaurant;
  index: number;
  setIsSaveEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<string | boolean>>;
  reloadRestaurants: () => Promise<void>;
  expanded: boolean;
  onAccordionChange: () => void;
  onNext: () => void;
  isLastElement: boolean;
};

export default function RestaurantItem({
  restaurant,
  index,
  setIsSaveEnabled,
  setLoading,
  reloadRestaurants,
  expanded,
  onAccordionChange,
  onNext,
  isLastElement,
}: Props) {
  return (
    <Draggable draggableId={restaurant.id} index={index}>
      {(provided, snapshot) => (
        <Paper
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            borderRadius: 4,
            boxShadow: snapshot.isDragging
              ? "0 4px 12px rgba(0, 0, 0, 0.2)"
              : "0 1px 3px rgba(0, 0, 0, 0.1)",
            backgroundColor: snapshot.isDragging
              ? "#e3f2fd"
              : "background.paper",
          }}
        >
          <RestaurantAccordion
            restaurant={restaurant}
            setIsSaveEnabled={setIsSaveEnabled}
            setLoading={setLoading}
            reloadRestaurants={reloadRestaurants}
            expanded={expanded}
            onAccordionChange={onAccordionChange}
            onNext={onNext}
            isLastElement={isLastElement}
          />
        </Paper>
      )}
    </Draggable>
  );
}
