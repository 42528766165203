import { Authenticator } from "@aws-amplify/ui-react";
import { Button, createTheme, ThemeProvider } from "@mui/material";
import { Layout, Typography } from "antd";
import { useNavigate } from "react-router-dom";

import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";

import { AppContextProvider } from "./context/app-context";
import AppRoutes from "./components/app-routes";
import LoadingOverlay from "./components/loading-overlay";
import SideMenu from "./components/side-menu";
import useAppLoader from "./hooks/useAppLoader";

import "./App.css";
import "@aws-amplify/ui-react/styles.css";

import packageJson from "../package.json";
import Colors from "./assets/colors";

const currentVersion = packageJson.version;

Amplify.configure(awsExports);

function WithAppLoader() {
  const { appInitialized } = useAppLoader();

  const theme = createTheme({
    palette: {
      primary: {
        main: Colors.primaryHex,
      },
      secondary: {
        main: Colors.secondaryHex,
        contrastText: "#fff",
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <LoadingOverlay
          loading={!appInitialized}
          text={"Einen Augenblick bitte, das Dashboard wird initialisiert"}
        />
        {appInitialized && <AppWithProviders />}
      </ThemeProvider>
    </>
  );
}

function AppWithProviders() {
  const navigate = useNavigate();

  return (
    <Authenticator variation="modal" hideSignUp>
      {({ signOut, user }) => (
        <>
          <main
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: 16,
            }}
          >
            <h1>Hello {user?.attributes?.email}</h1>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                signOut && signOut();

                navigate("/");
              }}
            >
              ABMELDEN
            </Button>
          </main>

          {user?.attributes?.email && (
            <AppContextProvider>
              <App />
            </AppContextProvider>
          )}
        </>
      )}
    </Authenticator>
  );
}

function App() {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout.Sider style={styles.sider}>
        <SideMenu />
      </Layout.Sider>

      <Layout>
        <Layout.Header style={styles.header as any}>
          <Typography.Title style={styles.title}>
            Admin Dashboard
          </Typography.Title>
        </Layout.Header>

        <Layout.Content>
          <AppRoutes />
        </Layout.Content>
        <Layout.Footer style={{ textAlign: "center" }}>
          v{currentVersion} | Tasty Delivery Admin Dashboard ©2022
        </Layout.Footer>
      </Layout>
    </Layout>
  );
}

const styles = {
  sider: {
    height: "100vh",
    backgroundColor: "white",
  },
  header: {
    backgroundColor: "white",
    textAlign: "center",
    padding: 8,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    margin: 0,
    padding: 0,
  },
};

export default WithAppLoader;
