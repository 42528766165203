/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createPaymentIntent = /* GraphQL */ `mutation CreatePaymentIntent(
  $amount: Int!
  $customerID: String
  $restaurantID: String
  $email: String
  $details: AWSJSON
  $deliveryFee: Float
  $restaurantFee: Float
  $customerFee: Float
  $voucherCode: String
  $voucherAmountOff: Float
  $voucherType: String
  $voucherPercentOff: Float
  $totalPrice: Float
  $totalPriceWithDiscount: Float
  $deliveryMethod: DeliveryMethod
  $deliveryProvider: DeliveryProvider
) {
  createPaymentIntent(
    amount: $amount
    customerID: $customerID
    restaurantID: $restaurantID
    email: $email
    details: $details
    deliveryFee: $deliveryFee
    restaurantFee: $restaurantFee
    customerFee: $customerFee
    voucherCode: $voucherCode
    voucherAmountOff: $voucherAmountOff
    voucherType: $voucherType
    voucherPercentOff: $voucherPercentOff
    totalPrice: $totalPrice
    totalPriceWithDiscount: $totalPriceWithDiscount
    deliveryMethod: $deliveryMethod
    deliveryProvider: $deliveryProvider
  ) {
    paymentIntentID
    clientSecret
    metadata {
      customerID
      restaurantID
      orderID
      details
      deliveryFee
      restaurantFee
      customerFee
      __typename
    }
    charge {
      id
      object
      amount
      amount_captured
      amount_refunded
      balance_transaction
      billing_details {
        address {
          city
          country
          line1
          line2
          postal_code
          state
          __typename
        }
        email
        name
        phone
        iban
        __typename
      }
      captured
      created
      currency
      disputed
      livemode
      paid
      payment_intent
      payment_method
      receipt_url
      refunded
      refunds {
        object
        has_more
        url
        __typename
      }
      status
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaymentIntentMutationVariables,
  APITypes.CreatePaymentIntentMutation
>;
export const updatePaymentIntent = /* GraphQL */ `mutation UpdatePaymentIntent(
  $paymentIntentID: String!
  $orderID: String
  $amount: Int
  $customerFee: Float
  $customerID: String
  $deliveryFee: Float
  $deliveryMethod: DeliveryMethod
  $deliveryProvider: DeliveryProvider
  $details: AWSJSON
  $email: String
  $restaurantFee: Float
  $restaurantID: String
  $totalPrice: Float
  $totalPriceWithDiscount: Float
  $voucherAmountOff: Float
  $voucherCode: String
  $voucherPercentOff: Float
  $voucherType: String
) {
  updatePaymentIntent(
    paymentIntentID: $paymentIntentID
    orderID: $orderID
    amount: $amount
    customerFee: $customerFee
    customerID: $customerID
    deliveryFee: $deliveryFee
    deliveryMethod: $deliveryMethod
    deliveryProvider: $deliveryProvider
    details: $details
    email: $email
    restaurantFee: $restaurantFee
    restaurantID: $restaurantID
    totalPrice: $totalPrice
    totalPriceWithDiscount: $totalPriceWithDiscount
    voucherAmountOff: $voucherAmountOff
    voucherCode: $voucherCode
    voucherPercentOff: $voucherPercentOff
    voucherType: $voucherType
  ) {
    paymentIntentID
    clientSecret
    metadata {
      customerID
      restaurantID
      orderID
      details
      deliveryFee
      restaurantFee
      customerFee
      __typename
    }
    charge {
      id
      object
      amount
      amount_captured
      amount_refunded
      balance_transaction
      billing_details {
        address {
          city
          country
          line1
          line2
          postal_code
          state
          __typename
        }
        email
        name
        phone
        iban
        __typename
      }
      captured
      created
      currency
      disputed
      livemode
      paid
      payment_intent
      payment_method
      receipt_url
      refunded
      refunds {
        object
        has_more
        url
        __typename
      }
      status
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaymentIntentMutationVariables,
  APITypes.UpdatePaymentIntentMutation
>;
export const getPaymentIntent = /* GraphQL */ `mutation GetPaymentIntent($paymentIntentID: String!) {
  getPaymentIntent(paymentIntentID: $paymentIntentID) {
    paymentIntentID
    clientSecret
    metadata {
      customerID
      restaurantID
      orderID
      details
      deliveryFee
      restaurantFee
      customerFee
      __typename
    }
    charge {
      id
      object
      amount
      amount_captured
      amount_refunded
      balance_transaction
      billing_details {
        address {
          city
          country
          line1
          line2
          postal_code
          state
          __typename
        }
        email
        name
        phone
        iban
        __typename
      }
      captured
      created
      currency
      disputed
      livemode
      paid
      payment_intent
      payment_method
      receipt_url
      refunded
      refunds {
        object
        has_more
        url
        __typename
      }
      status
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GetPaymentIntentMutationVariables,
  APITypes.GetPaymentIntentMutation
>;
export const calculateRestaurantInvoice = /* GraphQL */ `mutation CalculateRestaurantInvoice(
  $restaurantID: String!
  $startDate: String!
  $endDate: String!
) {
  calculateRestaurantInvoice(
    restaurantID: $restaurantID
    startDate: $startDate
    endDate: $endDate
  ) {
    customerID
    hasRefund
    invoiceCreatedAt
    invoiceID
    invoiceTotalNet
    invoiceTotalWithRefund
    orderID
    paymentIntentID
    refundAmount
    restaurantID
    voucherChargedTo
    voucherCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CalculateRestaurantInvoiceMutationVariables,
  APITypes.CalculateRestaurantInvoiceMutation
>;
export const calculateCustomerInvoice = /* GraphQL */ `mutation CalculateCustomerInvoice($invoiceID: String!) {
  calculateCustomerInvoice(invoiceID: $invoiceID) {
    invoiceID
    customerID
    orderID
    paymentIntentID
    pdfFileURL
    restaurantID
    totalPrice
    totalPriceWithDiscount
    deliveryFee
    restaurantFee
    customerFee
    voucherAmountOff
    voucherCode
    voucherPercentOff
    voucherType
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CalculateCustomerInvoiceMutationVariables,
  APITypes.CalculateCustomerInvoiceMutation
>;
export const checkPaymentStatus = /* GraphQL */ `mutation CheckPaymentStatus($orderID: String!) {
  checkPaymentStatus(orderID: $orderID) {
    isPaid
    orderID
    paymentIntentID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CheckPaymentStatusMutationVariables,
  APITypes.CheckPaymentStatusMutation
>;
export const createInvoice = /* GraphQL */ `mutation CreateInvoice(
  $input: CreateInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  createInvoice(input: $input, condition: $condition) {
    id
    amount
    customerFee
    deliveryFee
    restaurantFee
    customerID
    customerEmail
    restaurantID
    paymentIntent
    paymentIntentID
    details {
      name
      description
      price
      options {
        title
        items {
          title
          price
          __typename
        }
        __typename
      }
      __typename
    }
    pdfInvoice
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInvoiceMutationVariables,
  APITypes.CreateInvoiceMutation
>;
export const updateInvoice = /* GraphQL */ `mutation UpdateInvoice(
  $input: UpdateInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  updateInvoice(input: $input, condition: $condition) {
    id
    amount
    customerFee
    deliveryFee
    restaurantFee
    customerID
    customerEmail
    restaurantID
    paymentIntent
    paymentIntentID
    details {
      name
      description
      price
      options {
        title
        items {
          title
          price
          __typename
        }
        __typename
      }
      __typename
    }
    pdfInvoice
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInvoiceMutationVariables,
  APITypes.UpdateInvoiceMutation
>;
export const deleteInvoice = /* GraphQL */ `mutation DeleteInvoice(
  $input: DeleteInvoiceInput!
  $condition: ModelInvoiceConditionInput
) {
  deleteInvoice(input: $input, condition: $condition) {
    id
    amount
    customerFee
    deliveryFee
    restaurantFee
    customerID
    customerEmail
    restaurantID
    paymentIntent
    paymentIntentID
    details {
      name
      description
      price
      options {
        title
        items {
          title
          price
          __typename
        }
        __typename
      }
      __typename
    }
    pdfInvoice
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInvoiceMutationVariables,
  APITypes.DeleteInvoiceMutation
>;
export const createDishCategory = /* GraphQL */ `mutation CreateDishCategory(
  $input: CreateDishCategoryInput!
  $condition: ModelDishCategoryConditionInput
) {
  createDishCategory(input: $input, condition: $condition) {
    id
    name
    description
    imageKey
    restaurantID
    order
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDishCategoryMutationVariables,
  APITypes.CreateDishCategoryMutation
>;
export const updateDishCategory = /* GraphQL */ `mutation UpdateDishCategory(
  $input: UpdateDishCategoryInput!
  $condition: ModelDishCategoryConditionInput
) {
  updateDishCategory(input: $input, condition: $condition) {
    id
    name
    description
    imageKey
    restaurantID
    order
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDishCategoryMutationVariables,
  APITypes.UpdateDishCategoryMutation
>;
export const deleteDishCategory = /* GraphQL */ `mutation DeleteDishCategory(
  $input: DeleteDishCategoryInput!
  $condition: ModelDishCategoryConditionInput
) {
  deleteDishCategory(input: $input, condition: $condition) {
    id
    name
    description
    imageKey
    restaurantID
    order
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDishCategoryMutationVariables,
  APITypes.DeleteDishCategoryMutation
>;
export const createDishOptionItem = /* GraphQL */ `mutation CreateDishOptionItem(
  $input: CreateDishOptionItemInput!
  $condition: ModelDishOptionItemConditionInput
) {
  createDishOptionItem(input: $input, condition: $condition) {
    id
    title
    description
    price
    dishOptionID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDishOptionItemMutationVariables,
  APITypes.CreateDishOptionItemMutation
>;
export const updateDishOptionItem = /* GraphQL */ `mutation UpdateDishOptionItem(
  $input: UpdateDishOptionItemInput!
  $condition: ModelDishOptionItemConditionInput
) {
  updateDishOptionItem(input: $input, condition: $condition) {
    id
    title
    description
    price
    dishOptionID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDishOptionItemMutationVariables,
  APITypes.UpdateDishOptionItemMutation
>;
export const deleteDishOptionItem = /* GraphQL */ `mutation DeleteDishOptionItem(
  $input: DeleteDishOptionItemInput!
  $condition: ModelDishOptionItemConditionInput
) {
  deleteDishOptionItem(input: $input, condition: $condition) {
    id
    title
    description
    price
    dishOptionID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDishOptionItemMutationVariables,
  APITypes.DeleteDishOptionItemMutation
>;
export const createDishOption = /* GraphQL */ `mutation CreateDishOption(
  $input: CreateDishOptionInput!
  $condition: ModelDishOptionConditionInput
) {
  createDishOption(input: $input, condition: $condition) {
    id
    title
    description
    multipleSelection
    obligatory
    items {
      items {
        id
        title
        description
        price
        dishOptionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    dishIDs
    restaurantID
    basketDishID
    orderDishID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDishOptionMutationVariables,
  APITypes.CreateDishOptionMutation
>;
export const updateDishOption = /* GraphQL */ `mutation UpdateDishOption(
  $input: UpdateDishOptionInput!
  $condition: ModelDishOptionConditionInput
) {
  updateDishOption(input: $input, condition: $condition) {
    id
    title
    description
    multipleSelection
    obligatory
    items {
      items {
        id
        title
        description
        price
        dishOptionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    dishIDs
    restaurantID
    basketDishID
    orderDishID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDishOptionMutationVariables,
  APITypes.UpdateDishOptionMutation
>;
export const deleteDishOption = /* GraphQL */ `mutation DeleteDishOption(
  $input: DeleteDishOptionInput!
  $condition: ModelDishOptionConditionInput
) {
  deleteDishOption(input: $input, condition: $condition) {
    id
    title
    description
    multipleSelection
    obligatory
    items {
      items {
        id
        title
        description
        price
        dishOptionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    dishIDs
    restaurantID
    basketDishID
    orderDishID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDishOptionMutationVariables,
  APITypes.DeleteDishOptionMutation
>;
export const createDish = /* GraphQL */ `mutation CreateDish(
  $input: CreateDishInput!
  $condition: ModelDishConditionInput
) {
  createDish(input: $input, condition: $condition) {
    id
    name
    image
    description
    shortDescription
    price
    restaurantID
    soldOut
    category {
      id
      name
      description
      imageKey
      restaurantID
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    allergens {
      id
      name
      __typename
    }
    dishType
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    dishCategoryId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDishMutationVariables,
  APITypes.CreateDishMutation
>;
export const updateDish = /* GraphQL */ `mutation UpdateDish(
  $input: UpdateDishInput!
  $condition: ModelDishConditionInput
) {
  updateDish(input: $input, condition: $condition) {
    id
    name
    image
    description
    shortDescription
    price
    restaurantID
    soldOut
    category {
      id
      name
      description
      imageKey
      restaurantID
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    allergens {
      id
      name
      __typename
    }
    dishType
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    dishCategoryId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDishMutationVariables,
  APITypes.UpdateDishMutation
>;
export const deleteDish = /* GraphQL */ `mutation DeleteDish(
  $input: DeleteDishInput!
  $condition: ModelDishConditionInput
) {
  deleteDish(input: $input, condition: $condition) {
    id
    name
    image
    description
    shortDescription
    price
    restaurantID
    soldOut
    category {
      id
      name
      description
      imageKey
      restaurantID
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    allergens {
      id
      name
      __typename
    }
    dishType
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    dishCategoryId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDishMutationVariables,
  APITypes.DeleteDishMutation
>;
export const createBusinessHourRestaurant = /* GraphQL */ `mutation CreateBusinessHourRestaurant(
  $input: CreateBusinessHourRestaurantInput!
  $condition: ModelBusinessHourRestaurantConditionInput
) {
  createBusinessHourRestaurant(input: $input, condition: $condition) {
    id
    restaurantID
    periods {
      label
      start
      end
      __typename
    }
    day
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBusinessHourRestaurantMutationVariables,
  APITypes.CreateBusinessHourRestaurantMutation
>;
export const updateBusinessHourRestaurant = /* GraphQL */ `mutation UpdateBusinessHourRestaurant(
  $input: UpdateBusinessHourRestaurantInput!
  $condition: ModelBusinessHourRestaurantConditionInput
) {
  updateBusinessHourRestaurant(input: $input, condition: $condition) {
    id
    restaurantID
    periods {
      label
      start
      end
      __typename
    }
    day
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBusinessHourRestaurantMutationVariables,
  APITypes.UpdateBusinessHourRestaurantMutation
>;
export const deleteBusinessHourRestaurant = /* GraphQL */ `mutation DeleteBusinessHourRestaurant(
  $input: DeleteBusinessHourRestaurantInput!
  $condition: ModelBusinessHourRestaurantConditionInput
) {
  deleteBusinessHourRestaurant(input: $input, condition: $condition) {
    id
    restaurantID
    periods {
      label
      start
      end
      __typename
    }
    day
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBusinessHourRestaurantMutationVariables,
  APITypes.DeleteBusinessHourRestaurantMutation
>;
export const createBusinessHourTasty = /* GraphQL */ `mutation CreateBusinessHourTasty(
  $input: CreateBusinessHourTastyInput!
  $condition: ModelBusinessHourTastyConditionInput
) {
  createBusinessHourTasty(input: $input, condition: $condition) {
    id
    day
    appConfigID
    periods {
      label
      start
      end
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBusinessHourTastyMutationVariables,
  APITypes.CreateBusinessHourTastyMutation
>;
export const updateBusinessHourTasty = /* GraphQL */ `mutation UpdateBusinessHourTasty(
  $input: UpdateBusinessHourTastyInput!
  $condition: ModelBusinessHourTastyConditionInput
) {
  updateBusinessHourTasty(input: $input, condition: $condition) {
    id
    day
    appConfigID
    periods {
      label
      start
      end
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBusinessHourTastyMutationVariables,
  APITypes.UpdateBusinessHourTastyMutation
>;
export const deleteBusinessHourTasty = /* GraphQL */ `mutation DeleteBusinessHourTasty(
  $input: DeleteBusinessHourTastyInput!
  $condition: ModelBusinessHourTastyConditionInput
) {
  deleteBusinessHourTasty(input: $input, condition: $condition) {
    id
    day
    appConfigID
    periods {
      label
      start
      end
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBusinessHourTastyMutationVariables,
  APITypes.DeleteBusinessHourTastyMutation
>;
export const createAppConfig = /* GraphQL */ `mutation CreateAppConfig(
  $input: CreateAppConfigInput!
  $condition: ModelAppConfigConditionInput
) {
  createAppConfig(input: $input, condition: $condition) {
    id
    customerFee
    deliveryFee
    restaurantFee
    minimumOrderValue
    openingHours
    businessHours {
      items {
        id
        day
        appConfigID
        periods {
          label
          start
          end
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    maintenance {
      active
      title
      description
      type
      __typename
    }
    taxRate
    customData
    banner {
      visible
      imageURL
      linkURL
      __typename
    }
    orderStop {
      active
      title
      description
      __typename
    }
    voucherEnabled
    isTastyDelivering
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAppConfigMutationVariables,
  APITypes.CreateAppConfigMutation
>;
export const updateAppConfig = /* GraphQL */ `mutation UpdateAppConfig(
  $input: UpdateAppConfigInput!
  $condition: ModelAppConfigConditionInput
) {
  updateAppConfig(input: $input, condition: $condition) {
    id
    customerFee
    deliveryFee
    restaurantFee
    minimumOrderValue
    openingHours
    businessHours {
      items {
        id
        day
        appConfigID
        periods {
          label
          start
          end
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    maintenance {
      active
      title
      description
      type
      __typename
    }
    taxRate
    customData
    banner {
      visible
      imageURL
      linkURL
      __typename
    }
    orderStop {
      active
      title
      description
      __typename
    }
    voucherEnabled
    isTastyDelivering
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAppConfigMutationVariables,
  APITypes.UpdateAppConfigMutation
>;
export const deleteAppConfig = /* GraphQL */ `mutation DeleteAppConfig(
  $input: DeleteAppConfigInput!
  $condition: ModelAppConfigConditionInput
) {
  deleteAppConfig(input: $input, condition: $condition) {
    id
    customerFee
    deliveryFee
    restaurantFee
    minimumOrderValue
    openingHours
    businessHours {
      items {
        id
        day
        appConfigID
        periods {
          label
          start
          end
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    maintenance {
      active
      title
      description
      type
      __typename
    }
    taxRate
    customData
    banner {
      visible
      imageURL
      linkURL
      __typename
    }
    orderStop {
      active
      title
      description
      __typename
    }
    voucherEnabled
    isTastyDelivering
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAppConfigMutationVariables,
  APITypes.DeleteAppConfigMutation
>;
export const createDriver = /* GraphQL */ `mutation CreateDriver(
  $input: CreateDriverInput!
  $condition: ModelDriverConditionInput
) {
  createDriver(input: $input, condition: $condition) {
    id
    name
    sub
    email
    password
    lat
    lng
    transportationMode
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDriverMutationVariables,
  APITypes.CreateDriverMutation
>;
export const updateDriver = /* GraphQL */ `mutation UpdateDriver(
  $input: UpdateDriverInput!
  $condition: ModelDriverConditionInput
) {
  updateDriver(input: $input, condition: $condition) {
    id
    name
    sub
    email
    password
    lat
    lng
    transportationMode
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDriverMutationVariables,
  APITypes.UpdateDriverMutation
>;
export const deleteDriver = /* GraphQL */ `mutation DeleteDriver(
  $input: DeleteDriverInput!
  $condition: ModelDriverConditionInput
) {
  deleteDriver(input: $input, condition: $condition) {
    id
    name
    sub
    email
    password
    lat
    lng
    transportationMode
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDriverMutationVariables,
  APITypes.DeleteDriverMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    sub
    name
    address
    addressData {
      street
      city
      country
      district
      formattedAddress
      lat
      lng
      __typename
    }
    email
    phone
    lat
    lng
    Orders {
      items {
        id
        userID
        Restaurant {
          id
          adminSub
          listRanking
          name
          minDeliveryTime
          maxDeliveryTime
          rating
          address
          lat
          lng
          imageStorageKey
          status
          phone
          email
          emailPrinter
          autoPickUp
          customData
          printerGCMToken
          loggingEnabled
          deliveryProvider
          defaultDeliveryFee
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        total
        totalPaid
        voucherCode
        voucherAmountOff
        voucherType
        voucherPercentOff
        status
        OrderDishes {
          nextToken
          startedAt
          __typename
        }
        Driver {
          id
          name
          sub
          email
          password
          lat
          lng
          transportationMode
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        read
        printed
        orderNumber
        note
        ready
        createdAt
        orderRestaurantId
        deliveryMethod
        deliveryProvider
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderDriverId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Baskets {
      items {
        id
        BasketDishes {
          nextToken
          startedAt
          __typename
        }
        userID
        restaurantID
        note
        deliveryMethod
        deliveryProvider
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deliveryAddressID
    DeliveryAddresses {
      items {
        id
        name
        addressData {
          street
          city
          country
          district
          formattedAddress
          lat
          lng
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    invoices {
      items {
        id
        amount
        customerFee
        deliveryFee
        restaurantFee
        customerID
        customerEmail
        restaurantID
        paymentIntent
        paymentIntentID
        details {
          name
          description
          price
          __typename
        }
        pdfInvoice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    newsletter
    company
    birthday
    stripeCustomerID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    sub
    name
    address
    addressData {
      street
      city
      country
      district
      formattedAddress
      lat
      lng
      __typename
    }
    email
    phone
    lat
    lng
    Orders {
      items {
        id
        userID
        Restaurant {
          id
          adminSub
          listRanking
          name
          minDeliveryTime
          maxDeliveryTime
          rating
          address
          lat
          lng
          imageStorageKey
          status
          phone
          email
          emailPrinter
          autoPickUp
          customData
          printerGCMToken
          loggingEnabled
          deliveryProvider
          defaultDeliveryFee
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        total
        totalPaid
        voucherCode
        voucherAmountOff
        voucherType
        voucherPercentOff
        status
        OrderDishes {
          nextToken
          startedAt
          __typename
        }
        Driver {
          id
          name
          sub
          email
          password
          lat
          lng
          transportationMode
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        read
        printed
        orderNumber
        note
        ready
        createdAt
        orderRestaurantId
        deliveryMethod
        deliveryProvider
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderDriverId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Baskets {
      items {
        id
        BasketDishes {
          nextToken
          startedAt
          __typename
        }
        userID
        restaurantID
        note
        deliveryMethod
        deliveryProvider
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deliveryAddressID
    DeliveryAddresses {
      items {
        id
        name
        addressData {
          street
          city
          country
          district
          formattedAddress
          lat
          lng
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    invoices {
      items {
        id
        amount
        customerFee
        deliveryFee
        restaurantFee
        customerID
        customerEmail
        restaurantID
        paymentIntent
        paymentIntentID
        details {
          name
          description
          price
          __typename
        }
        pdfInvoice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    newsletter
    company
    birthday
    stripeCustomerID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    sub
    name
    address
    addressData {
      street
      city
      country
      district
      formattedAddress
      lat
      lng
      __typename
    }
    email
    phone
    lat
    lng
    Orders {
      items {
        id
        userID
        Restaurant {
          id
          adminSub
          listRanking
          name
          minDeliveryTime
          maxDeliveryTime
          rating
          address
          lat
          lng
          imageStorageKey
          status
          phone
          email
          emailPrinter
          autoPickUp
          customData
          printerGCMToken
          loggingEnabled
          deliveryProvider
          defaultDeliveryFee
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        total
        totalPaid
        voucherCode
        voucherAmountOff
        voucherType
        voucherPercentOff
        status
        OrderDishes {
          nextToken
          startedAt
          __typename
        }
        Driver {
          id
          name
          sub
          email
          password
          lat
          lng
          transportationMode
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        read
        printed
        orderNumber
        note
        ready
        createdAt
        orderRestaurantId
        deliveryMethod
        deliveryProvider
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderDriverId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Baskets {
      items {
        id
        BasketDishes {
          nextToken
          startedAt
          __typename
        }
        userID
        restaurantID
        note
        deliveryMethod
        deliveryProvider
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deliveryAddressID
    DeliveryAddresses {
      items {
        id
        name
        addressData {
          street
          city
          country
          district
          formattedAddress
          lat
          lng
          __typename
        }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    invoices {
      items {
        id
        amount
        customerFee
        deliveryFee
        restaurantFee
        customerID
        customerEmail
        restaurantID
        paymentIntent
        paymentIntentID
        details {
          name
          description
          price
          __typename
        }
        pdfInvoice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    newsletter
    company
    birthday
    stripeCustomerID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createBasketDish = /* GraphQL */ `mutation CreateBasketDish(
  $input: CreateBasketDishInput!
  $condition: ModelBasketDishConditionInput
) {
  createBasketDish(input: $input, condition: $condition) {
    id
    quantity
    Dish {
      id
      name
      image
      description
      shortDescription
      price
      restaurantID
      soldOut
      category {
        id
        name
        description
        imageKey
        restaurantID
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      allergens {
        id
        name
        __typename
      }
      dishType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dishCategoryId
      __typename
    }
    basketID
    selectedDishOptions {
      items {
        id
        title
        description
        multipleSelection
        obligatory
        items {
          nextToken
          startedAt
          __typename
        }
        dishIDs
        restaurantID
        basketDishID
        orderDishID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    selectedDishOptionsJSON
    note
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    basketDishDishId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBasketDishMutationVariables,
  APITypes.CreateBasketDishMutation
>;
export const updateBasketDish = /* GraphQL */ `mutation UpdateBasketDish(
  $input: UpdateBasketDishInput!
  $condition: ModelBasketDishConditionInput
) {
  updateBasketDish(input: $input, condition: $condition) {
    id
    quantity
    Dish {
      id
      name
      image
      description
      shortDescription
      price
      restaurantID
      soldOut
      category {
        id
        name
        description
        imageKey
        restaurantID
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      allergens {
        id
        name
        __typename
      }
      dishType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dishCategoryId
      __typename
    }
    basketID
    selectedDishOptions {
      items {
        id
        title
        description
        multipleSelection
        obligatory
        items {
          nextToken
          startedAt
          __typename
        }
        dishIDs
        restaurantID
        basketDishID
        orderDishID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    selectedDishOptionsJSON
    note
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    basketDishDishId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBasketDishMutationVariables,
  APITypes.UpdateBasketDishMutation
>;
export const deleteBasketDish = /* GraphQL */ `mutation DeleteBasketDish(
  $input: DeleteBasketDishInput!
  $condition: ModelBasketDishConditionInput
) {
  deleteBasketDish(input: $input, condition: $condition) {
    id
    quantity
    Dish {
      id
      name
      image
      description
      shortDescription
      price
      restaurantID
      soldOut
      category {
        id
        name
        description
        imageKey
        restaurantID
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      allergens {
        id
        name
        __typename
      }
      dishType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dishCategoryId
      __typename
    }
    basketID
    selectedDishOptions {
      items {
        id
        title
        description
        multipleSelection
        obligatory
        items {
          nextToken
          startedAt
          __typename
        }
        dishIDs
        restaurantID
        basketDishID
        orderDishID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    selectedDishOptionsJSON
    note
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    basketDishDishId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBasketDishMutationVariables,
  APITypes.DeleteBasketDishMutation
>;
export const createBasket = /* GraphQL */ `mutation CreateBasket(
  $input: CreateBasketInput!
  $condition: ModelBasketConditionInput
) {
  createBasket(input: $input, condition: $condition) {
    id
    BasketDishes {
      items {
        id
        quantity
        Dish {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        basketID
        selectedDishOptions {
          nextToken
          startedAt
          __typename
        }
        selectedDishOptionsJSON
        note
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        basketDishDishId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    userID
    restaurantID
    note
    deliveryMethod
    deliveryProvider
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBasketMutationVariables,
  APITypes.CreateBasketMutation
>;
export const updateBasket = /* GraphQL */ `mutation UpdateBasket(
  $input: UpdateBasketInput!
  $condition: ModelBasketConditionInput
) {
  updateBasket(input: $input, condition: $condition) {
    id
    BasketDishes {
      items {
        id
        quantity
        Dish {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        basketID
        selectedDishOptions {
          nextToken
          startedAt
          __typename
        }
        selectedDishOptionsJSON
        note
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        basketDishDishId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    userID
    restaurantID
    note
    deliveryMethod
    deliveryProvider
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBasketMutationVariables,
  APITypes.UpdateBasketMutation
>;
export const deleteBasket = /* GraphQL */ `mutation DeleteBasket(
  $input: DeleteBasketInput!
  $condition: ModelBasketConditionInput
) {
  deleteBasket(input: $input, condition: $condition) {
    id
    BasketDishes {
      items {
        id
        quantity
        Dish {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        basketID
        selectedDishOptions {
          nextToken
          startedAt
          __typename
        }
        selectedDishOptionsJSON
        note
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        basketDishDishId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    userID
    restaurantID
    note
    deliveryMethod
    deliveryProvider
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBasketMutationVariables,
  APITypes.DeleteBasketMutation
>;
export const createOrderDish = /* GraphQL */ `mutation CreateOrderDish(
  $input: CreateOrderDishInput!
  $condition: ModelOrderDishConditionInput
) {
  createOrderDish(input: $input, condition: $condition) {
    id
    quantity
    Dish {
      id
      name
      image
      description
      shortDescription
      price
      restaurantID
      soldOut
      category {
        id
        name
        description
        imageKey
        restaurantID
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      allergens {
        id
        name
        __typename
      }
      dishType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dishCategoryId
      __typename
    }
    orderID
    selectedDishOptions {
      items {
        id
        title
        description
        multipleSelection
        obligatory
        items {
          nextToken
          startedAt
          __typename
        }
        dishIDs
        restaurantID
        basketDishID
        orderDishID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    selectedDishOptionsJSON
    note
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    orderDishDishId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderDishMutationVariables,
  APITypes.CreateOrderDishMutation
>;
export const updateOrderDish = /* GraphQL */ `mutation UpdateOrderDish(
  $input: UpdateOrderDishInput!
  $condition: ModelOrderDishConditionInput
) {
  updateOrderDish(input: $input, condition: $condition) {
    id
    quantity
    Dish {
      id
      name
      image
      description
      shortDescription
      price
      restaurantID
      soldOut
      category {
        id
        name
        description
        imageKey
        restaurantID
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      allergens {
        id
        name
        __typename
      }
      dishType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dishCategoryId
      __typename
    }
    orderID
    selectedDishOptions {
      items {
        id
        title
        description
        multipleSelection
        obligatory
        items {
          nextToken
          startedAt
          __typename
        }
        dishIDs
        restaurantID
        basketDishID
        orderDishID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    selectedDishOptionsJSON
    note
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    orderDishDishId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderDishMutationVariables,
  APITypes.UpdateOrderDishMutation
>;
export const deleteOrderDish = /* GraphQL */ `mutation DeleteOrderDish(
  $input: DeleteOrderDishInput!
  $condition: ModelOrderDishConditionInput
) {
  deleteOrderDish(input: $input, condition: $condition) {
    id
    quantity
    Dish {
      id
      name
      image
      description
      shortDescription
      price
      restaurantID
      soldOut
      category {
        id
        name
        description
        imageKey
        restaurantID
        order
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      allergens {
        id
        name
        __typename
      }
      dishType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      dishCategoryId
      __typename
    }
    orderID
    selectedDishOptions {
      items {
        id
        title
        description
        multipleSelection
        obligatory
        items {
          nextToken
          startedAt
          __typename
        }
        dishIDs
        restaurantID
        basketDishID
        orderDishID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    selectedDishOptionsJSON
    note
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    orderDishDishId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrderDishMutationVariables,
  APITypes.DeleteOrderDishMutation
>;
export const createOrder = /* GraphQL */ `mutation CreateOrder(
  $input: CreateOrderInput!
  $condition: ModelOrderConditionInput
) {
  createOrder(input: $input, condition: $condition) {
    id
    userID
    Restaurant {
      id
      adminSub
      listRanking
      name
      image {
        logoKey
        teaserKey
        __typename
      }
      minDeliveryTime
      maxDeliveryTime
      rating
      address
      lat
      lng
      Dishes {
        items {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Baskets {
        items {
          id
          userID
          restaurantID
          note
          deliveryMethod
          deliveryProvider
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      categories {
        items {
          id
          name
          restaurantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      imageStorageKey
      status
      invoices {
        items {
          id
          amount
          customerFee
          deliveryFee
          restaurantFee
          customerID
          customerEmail
          restaurantID
          paymentIntent
          paymentIntentID
          pdfInvoice
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      phone
      email
      emailPrinter
      autoPickUp
      customData
      billingDetails {
        address {
          city
          country
          line1
          line2
          postal_code
          state
          __typename
        }
        email
        name
        phone
        iban
        __typename
      }
      printerGCMToken
      businessHours {
        items {
          id
          restaurantID
          day
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      loggingEnabled
      deliveryProvider
      defaultDeliveryFee
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    total
    totalPaid
    voucherCode
    voucherAmountOff
    voucherType
    voucherPercentOff
    status
    OrderDishes {
      items {
        id
        quantity
        Dish {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        orderID
        selectedDishOptions {
          nextToken
          startedAt
          __typename
        }
        selectedDishOptionsJSON
        note
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderDishDishId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Driver {
      id
      name
      sub
      email
      password
      lat
      lng
      transportationMode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    read
    printed
    orderNumber
    note
    ready
    createdAt
    orderRestaurantId
    deliveryMethod
    deliveryProvider
    updatedAt
    _version
    _deleted
    _lastChangedAt
    orderDriverId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderMutationVariables,
  APITypes.CreateOrderMutation
>;
export const updateOrder = /* GraphQL */ `mutation UpdateOrder(
  $input: UpdateOrderInput!
  $condition: ModelOrderConditionInput
) {
  updateOrder(input: $input, condition: $condition) {
    id
    userID
    Restaurant {
      id
      adminSub
      listRanking
      name
      image {
        logoKey
        teaserKey
        __typename
      }
      minDeliveryTime
      maxDeliveryTime
      rating
      address
      lat
      lng
      Dishes {
        items {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Baskets {
        items {
          id
          userID
          restaurantID
          note
          deliveryMethod
          deliveryProvider
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      categories {
        items {
          id
          name
          restaurantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      imageStorageKey
      status
      invoices {
        items {
          id
          amount
          customerFee
          deliveryFee
          restaurantFee
          customerID
          customerEmail
          restaurantID
          paymentIntent
          paymentIntentID
          pdfInvoice
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      phone
      email
      emailPrinter
      autoPickUp
      customData
      billingDetails {
        address {
          city
          country
          line1
          line2
          postal_code
          state
          __typename
        }
        email
        name
        phone
        iban
        __typename
      }
      printerGCMToken
      businessHours {
        items {
          id
          restaurantID
          day
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      loggingEnabled
      deliveryProvider
      defaultDeliveryFee
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    total
    totalPaid
    voucherCode
    voucherAmountOff
    voucherType
    voucherPercentOff
    status
    OrderDishes {
      items {
        id
        quantity
        Dish {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        orderID
        selectedDishOptions {
          nextToken
          startedAt
          __typename
        }
        selectedDishOptionsJSON
        note
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderDishDishId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Driver {
      id
      name
      sub
      email
      password
      lat
      lng
      transportationMode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    read
    printed
    orderNumber
    note
    ready
    createdAt
    orderRestaurantId
    deliveryMethod
    deliveryProvider
    updatedAt
    _version
    _deleted
    _lastChangedAt
    orderDriverId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderMutationVariables,
  APITypes.UpdateOrderMutation
>;
export const deleteOrder = /* GraphQL */ `mutation DeleteOrder(
  $input: DeleteOrderInput!
  $condition: ModelOrderConditionInput
) {
  deleteOrder(input: $input, condition: $condition) {
    id
    userID
    Restaurant {
      id
      adminSub
      listRanking
      name
      image {
        logoKey
        teaserKey
        __typename
      }
      minDeliveryTime
      maxDeliveryTime
      rating
      address
      lat
      lng
      Dishes {
        items {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Baskets {
        items {
          id
          userID
          restaurantID
          note
          deliveryMethod
          deliveryProvider
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      categories {
        items {
          id
          name
          restaurantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      imageStorageKey
      status
      invoices {
        items {
          id
          amount
          customerFee
          deliveryFee
          restaurantFee
          customerID
          customerEmail
          restaurantID
          paymentIntent
          paymentIntentID
          pdfInvoice
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      phone
      email
      emailPrinter
      autoPickUp
      customData
      billingDetails {
        address {
          city
          country
          line1
          line2
          postal_code
          state
          __typename
        }
        email
        name
        phone
        iban
        __typename
      }
      printerGCMToken
      businessHours {
        items {
          id
          restaurantID
          day
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      loggingEnabled
      deliveryProvider
      defaultDeliveryFee
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    total
    totalPaid
    voucherCode
    voucherAmountOff
    voucherType
    voucherPercentOff
    status
    OrderDishes {
      items {
        id
        quantity
        Dish {
          id
          name
          image
          description
          shortDescription
          price
          restaurantID
          soldOut
          dishType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          dishCategoryId
          __typename
        }
        orderID
        selectedDishOptions {
          nextToken
          startedAt
          __typename
        }
        selectedDishOptionsJSON
        note
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orderDishDishId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Driver {
      id
      name
      sub
      email
      password
      lat
      lng
      transportationMode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    read
    printed
    orderNumber
    note
    ready
    createdAt
    orderRestaurantId
    deliveryMethod
    deliveryProvider
    updatedAt
    _version
    _deleted
    _lastChangedAt
    orderDriverId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrderMutationVariables,
  APITypes.DeleteOrderMutation
>;
export const createDeliveryAddress = /* GraphQL */ `mutation CreateDeliveryAddress(
  $input: CreateDeliveryAddressInput!
  $condition: ModelDeliveryAddressConditionInput
) {
  createDeliveryAddress(input: $input, condition: $condition) {
    id
    name
    addressData {
      street
      city
      country
      district
      formattedAddress
      lat
      lng
      __typename
    }
    userID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDeliveryAddressMutationVariables,
  APITypes.CreateDeliveryAddressMutation
>;
export const updateDeliveryAddress = /* GraphQL */ `mutation UpdateDeliveryAddress(
  $input: UpdateDeliveryAddressInput!
  $condition: ModelDeliveryAddressConditionInput
) {
  updateDeliveryAddress(input: $input, condition: $condition) {
    id
    name
    addressData {
      street
      city
      country
      district
      formattedAddress
      lat
      lng
      __typename
    }
    userID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDeliveryAddressMutationVariables,
  APITypes.UpdateDeliveryAddressMutation
>;
export const deleteDeliveryAddress = /* GraphQL */ `mutation DeleteDeliveryAddress(
  $input: DeleteDeliveryAddressInput!
  $condition: ModelDeliveryAddressConditionInput
) {
  deleteDeliveryAddress(input: $input, condition: $condition) {
    id
    name
    addressData {
      street
      city
      country
      district
      formattedAddress
      lat
      lng
      __typename
    }
    userID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDeliveryAddressMutationVariables,
  APITypes.DeleteDeliveryAddressMutation
>;
export const createRestaurantCategory = /* GraphQL */ `mutation CreateRestaurantCategory(
  $input: CreateRestaurantCategoryInput!
  $condition: ModelRestaurantCategoryConditionInput
) {
  createRestaurantCategory(input: $input, condition: $condition) {
    id
    name
    restaurantID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRestaurantCategoryMutationVariables,
  APITypes.CreateRestaurantCategoryMutation
>;
export const updateRestaurantCategory = /* GraphQL */ `mutation UpdateRestaurantCategory(
  $input: UpdateRestaurantCategoryInput!
  $condition: ModelRestaurantCategoryConditionInput
) {
  updateRestaurantCategory(input: $input, condition: $condition) {
    id
    name
    restaurantID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRestaurantCategoryMutationVariables,
  APITypes.UpdateRestaurantCategoryMutation
>;
export const deleteRestaurantCategory = /* GraphQL */ `mutation DeleteRestaurantCategory(
  $input: DeleteRestaurantCategoryInput!
  $condition: ModelRestaurantCategoryConditionInput
) {
  deleteRestaurantCategory(input: $input, condition: $condition) {
    id
    name
    restaurantID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRestaurantCategoryMutationVariables,
  APITypes.DeleteRestaurantCategoryMutation
>;
export const createRestaurant = /* GraphQL */ `mutation CreateRestaurant(
  $input: CreateRestaurantInput!
  $condition: ModelRestaurantConditionInput
) {
  createRestaurant(input: $input, condition: $condition) {
    id
    adminSub
    listRanking
    name
    image {
      logoKey
      teaserKey
      __typename
    }
    minDeliveryTime
    maxDeliveryTime
    rating
    address
    lat
    lng
    Dishes {
      items {
        id
        name
        image
        description
        shortDescription
        price
        restaurantID
        soldOut
        category {
          id
          name
          description
          imageKey
          restaurantID
          order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        allergens {
          id
          name
          __typename
        }
        dishType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dishCategoryId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Baskets {
      items {
        id
        BasketDishes {
          nextToken
          startedAt
          __typename
        }
        userID
        restaurantID
        note
        deliveryMethod
        deliveryProvider
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    categories {
      items {
        id
        name
        restaurantID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    imageStorageKey
    status
    invoices {
      items {
        id
        amount
        customerFee
        deliveryFee
        restaurantFee
        customerID
        customerEmail
        restaurantID
        paymentIntent
        paymentIntentID
        details {
          name
          description
          price
          __typename
        }
        pdfInvoice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    phone
    email
    emailPrinter
    autoPickUp
    customData
    billingDetails {
      address {
        city
        country
        line1
        line2
        postal_code
        state
        __typename
      }
      email
      name
      phone
      iban
      __typename
    }
    printerGCMToken
    businessHours {
      items {
        id
        restaurantID
        periods {
          label
          start
          end
          __typename
        }
        day
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    loggingEnabled
    deliveryProvider
    defaultDeliveryFee
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRestaurantMutationVariables,
  APITypes.CreateRestaurantMutation
>;
export const updateRestaurant = /* GraphQL */ `mutation UpdateRestaurant(
  $input: UpdateRestaurantInput!
  $condition: ModelRestaurantConditionInput
) {
  updateRestaurant(input: $input, condition: $condition) {
    id
    adminSub
    listRanking
    name
    image {
      logoKey
      teaserKey
      __typename
    }
    minDeliveryTime
    maxDeliveryTime
    rating
    address
    lat
    lng
    Dishes {
      items {
        id
        name
        image
        description
        shortDescription
        price
        restaurantID
        soldOut
        category {
          id
          name
          description
          imageKey
          restaurantID
          order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        allergens {
          id
          name
          __typename
        }
        dishType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dishCategoryId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Baskets {
      items {
        id
        BasketDishes {
          nextToken
          startedAt
          __typename
        }
        userID
        restaurantID
        note
        deliveryMethod
        deliveryProvider
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    categories {
      items {
        id
        name
        restaurantID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    imageStorageKey
    status
    invoices {
      items {
        id
        amount
        customerFee
        deliveryFee
        restaurantFee
        customerID
        customerEmail
        restaurantID
        paymentIntent
        paymentIntentID
        details {
          name
          description
          price
          __typename
        }
        pdfInvoice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    phone
    email
    emailPrinter
    autoPickUp
    customData
    billingDetails {
      address {
        city
        country
        line1
        line2
        postal_code
        state
        __typename
      }
      email
      name
      phone
      iban
      __typename
    }
    printerGCMToken
    businessHours {
      items {
        id
        restaurantID
        periods {
          label
          start
          end
          __typename
        }
        day
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    loggingEnabled
    deliveryProvider
    defaultDeliveryFee
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRestaurantMutationVariables,
  APITypes.UpdateRestaurantMutation
>;
export const deleteRestaurant = /* GraphQL */ `mutation DeleteRestaurant(
  $input: DeleteRestaurantInput!
  $condition: ModelRestaurantConditionInput
) {
  deleteRestaurant(input: $input, condition: $condition) {
    id
    adminSub
    listRanking
    name
    image {
      logoKey
      teaserKey
      __typename
    }
    minDeliveryTime
    maxDeliveryTime
    rating
    address
    lat
    lng
    Dishes {
      items {
        id
        name
        image
        description
        shortDescription
        price
        restaurantID
        soldOut
        category {
          id
          name
          description
          imageKey
          restaurantID
          order
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        allergens {
          id
          name
          __typename
        }
        dishType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        dishCategoryId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Baskets {
      items {
        id
        BasketDishes {
          nextToken
          startedAt
          __typename
        }
        userID
        restaurantID
        note
        deliveryMethod
        deliveryProvider
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    categories {
      items {
        id
        name
        restaurantID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    imageStorageKey
    status
    invoices {
      items {
        id
        amount
        customerFee
        deliveryFee
        restaurantFee
        customerID
        customerEmail
        restaurantID
        paymentIntent
        paymentIntentID
        details {
          name
          description
          price
          __typename
        }
        pdfInvoice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    phone
    email
    emailPrinter
    autoPickUp
    customData
    billingDetails {
      address {
        city
        country
        line1
        line2
        postal_code
        state
        __typename
      }
      email
      name
      phone
      iban
      __typename
    }
    printerGCMToken
    businessHours {
      items {
        id
        restaurantID
        periods {
          label
          start
          end
          __typename
        }
        day
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    loggingEnabled
    deliveryProvider
    defaultDeliveryFee
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRestaurantMutationVariables,
  APITypes.DeleteRestaurantMutation
>;
