import React from "react";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import {
  ArrowForward,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import BillingDetails from "./BillingDetails";
import DangerZone from "./DangerZone";
import { Restaurant } from "../../API";
import moment from "moment";

type Props = {
  restaurant: Restaurant;
  setIsSaveEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<string | boolean>>;
  reloadRestaurants: () => Promise<void>;
  expanded: boolean;
  onAccordionChange: () => void;
  onNext: () => void;
  isLastElement: boolean;
};

export default function RestaurantAccordion({
  restaurant,
  setIsSaveEnabled,
  setLoading,
  reloadRestaurants,
  expanded,
  onAccordionChange,
  onNext,
  isLastElement,
}: Props) {
  return (
    <Accordion
      sx={{ marginTop: 2 }}
      expanded={expanded}
      onChange={onAccordionChange}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box>
          <Typography variant="h6" fontWeight="bold">
            {restaurant.listRanking} - {restaurant.name}
          </Typography>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            sx={{ marginTop: 1 }}
          >
            {restaurant.address}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" justifyContent="space-between">
          <BillingDetails
            restaurant={restaurant}
            setIsSaveEnabled={setIsSaveEnabled}
          />
          <DangerZone
            restaurant={restaurant}
            reloadRestaurants={reloadRestaurants}
            setLoading={setLoading}
          />
        </Box>
      </AccordionDetails>

      <Divider />

      <AccordionActions
        sx={{
          paddingLeft: 3,
          paddingRight: 3,
          backgroundColor: "#f9f9f9",
        }}
      >
        <Box
          flex={1}
          display="flex"
          flexDirection={"row"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Box display="flex" flexDirection={"column"}>
            <Typography
              variant="caption"
              color="textSecondary"
              style={{ marginRight: 4 }}
            >
              Zu letzt aktualisiert:{" "}
              {moment(restaurant._lastChangedAt).format("MMM DD, YYYY - HH:mm")}
            </Typography>

            <Typography
              variant="caption"
              color="textSecondary"
              style={{ marginRight: 4 }}
            >
              ID: {restaurant.id}
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              style={{ marginRight: 4 }}
            >
              SUB: {restaurant.adminSub}
            </Typography>
          </Box>

          <Box>
            <Button
              variant="contained"
              onClick={onNext}
              endIcon={<ArrowForward />}
            >
              <Typography variant="button" sx={{ marginRight: 1 }}>
                {isLastElement ? "Done" : "Next"}
              </Typography>
            </Button>
          </Box>
        </Box>
      </AccordionActions>
    </Accordion>
  );
}
